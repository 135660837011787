<template>
  <div class="block-titled-links-list">
    <h3 class="__titled-links-list-title">
      {{ title }}
    </h3>
    <ul class="__titled-links-list-link-list">
      <li v-for="(link, index) in links" :key="`link-${index}`" class="__link-item" :class="{'--selected': link.url === selectedItemUrl}">
        <nuxt-link :to="link.url" :target="link.target || '_self'">
          {{ link.label }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import {ComputedRef, PropType} from 'vue';
import {Link} from '~/@types/common';
import {SymbolKind} from 'vscode-languageserver-types';
import Array = SymbolKind.Array;
import {useRoute} from '#app';
import {useListingStore} from '~/store/listing';
import router from '#app/plugins/router';

// PROPS
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  links: {
    type: Object as PropType<Array<Link>>,
    required: true,
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const route = useRoute();

/**
 * Get selected item from query
 */
const selectedItemUrl: ComputedRef<string | null> = computed(() => {
  if (route.query && route.path === '/listing/search' && route.query.keyword) {
    switch (route.query.keyword) {
      case 'Northland':
        return '/listing/search?keyword=Northland';
      case 'Auckland':
        return '/listing/search?keyword=Auckland';
      case 'Hamilton':
        return '/listing/search?keyword=Hamilton';
      case 'Tauranga':
        return '/listing/search?keyword=Tauranga';
      case 'Hawke\'s Bay':
        return '/listing/search?keyword=Hawke\'s Bay';
      case 'Wellington':
        return '/listing/search?keyword=Wellington';
      case 'Nelson':
        return '/listing/search?keyword=Nelson';
      case 'Blenheim':
        return '/listing/search?keyword=Blenheim';
      case 'Christchurch':
        return '/listing/search?keyword=Christchurch';
      case 'Queenstown':
        return '/listing/search?keyword=Queenstown';
      case 'Cromwell':
        return '/listing/search?keyword=Cromwell';
      case 'Dunedin':
        return '/listing/search?keyword=Dunedin';
      default:
        return null;
    }
  }
  return null;
});
</script>

<style lang="scss" scoped>
.block-titled-links-list {
  padding: 0 20px;
  color: rgb(var(--text-light));

  .__titled-links-list-title {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 40px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 40px;
      height: 3px;
      top: 30px;
      left: 0;
      margin: auto;
      background: rgb(var(--primary-light));
      transition: all 0.2s ease-in-out;
    }
  }

  .__titled-links-list-link-list {
    list-style: disc;
    list-style-position: inside;

    .__link-item, .__link-item a {
      padding-bottom: 12px;
      text-decoration: none;
      font-size: 14px;
      font-weight: 300;
      color: rgb(var(--text-light));
      opacity: 0.7;
      transition: opacity 0.15s 0.1s ease-in-out;

      &:hover {
        opacity: 1;
        transition: opacity 0.15s ease-in-out;
      }

      &.--selected, &.--selected a {
        opacity: 1;
      }
    }
  }
}
</style>
