// plugins/console.js
export default defineNuxtPlugin((nuxtApp) => {
  const devMode = useCookie('dev-mode');
  if (process.env.NODE_ENV === 'production' && devMode.value?.toLowerCase() !== 'true') {
    console.log = () => {};
    console.info = () => {};
    console.error = () => {};
    console.warn = () => {};
    // ...overwrite other methods you want to disable
  }
});
