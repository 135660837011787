<template>
  <transition name="fade" tag="div">
    <div v-if="listing && (!listing.category || listing.category !== 'Ad')" ref="listingWidgetRef" :key="`listing-widget-item`" class="blocks-listing" :class="`__${type} ${listing.isSold ? '__sold' : ''} ${isAFavorite ? '--favorite': ''} ${isLoading ? '--loading' : ''}`" @mouseenter="setHoveredListing(true)" @mouseleave="setHoveredListing(false)">
      <!-- Top Corner label -->
      <div v-if="topCornerLabel" class="__top-corner-label" :style="topCornerLabel && topCornerLabel.color ? `color: ${topCornerLabel.color};` : ''">
        {{ topCornerLabel && topCornerLabel.label ? topCornerLabel.label : topCornerLabel }}
      </div>

      <!-- Favorite button -->
      <div class="__favorite-button-wrapper">
        <ElementsAvatar :icon-src="isAFavorite ? favoriteSelectedIcon : favoriteIcon" icon-alt="Favorite icon" @click="toggleAddToFavorite();" />
      </div>

      <!-- Listing image -->
      <nuxt-link :to="disableLink === false ? linkUrl : ''" @click="triggerAnalyticsClickEvent">
        <div class="__listing-image-wrapper">
          <div class="__listing-image">
            <nuxt-img :src="typeof listing.heroImageUrl === 'string' ? listing.heroImageUrl : ''" fit="cover" height="240" width="390" loading="lazy" :alt="`${listing.name} cover image`" />
          </div>
          <div class="__listing-type-wrapper">
            <div v-for="(listingType, key) in listingTypes" :key="`listing-type-${key}`" class="__listing-type" :style="`background: ${listingTypeBgColor(listingType)};`">
              {{ listingType }}
            </div>
          </div>
        </div>
      </nuxt-link>

      <!-- Listing Info -->
      <div class="__listing-info-wrapper">
        <nuxt-link :to="disableLink === false ? linkUrl : ''" @click="triggerAnalyticsClickEvent">
          <!-- Listing Title -->
          <h4 class="__listing-title">
            {{ listing.name }}
          </h4>
        </nuxt-link>

        <!-- Listing Address -->
        <nuxt-link :to="disableLink === false ? linkUrl : ''" @click="triggerAnalyticsClickEvent">
          <div v-if="displayAddress" class="__listing-address-wrapper">
            <img v-lazy-load  class="__listing-address-icon" data-src="~/assets/images/icons/location.webp" alt="Location icon" width="10" height="15">
            <span class="__listing-address-text"> {{ displayAddress }}</span>
          </div>
        </nuxt-link>

        <!-- Listing Builder Logo -->
        <div v-if="listing.hidePrice !== true" class="__price">
          {{ listing.price ? `$${numberFormat(listing.price)}` : 'Price on application' }}
        </div>

        <!-- Listing Builder Logo -->
        <div v-if="listing.company && listing.company.logoUrl" class="__builder-logo-wrapper">
          <nuxt-link :to="`/builder/${listing.company.slug}/view`">
            <img :src="listing.company.logoUrl" alt="Builder logo" width="80" height="50" data-not-lazy>
          </nuxt-link>
        </div>

        <!-- Listing Icon Info -->
        <div class="__listing-icon-info-block">
          <ElementsButtonsIcon v-if="!isLand" :icon-src="beds" :label="`${listing.bedrooms || '-'}`" not-clickable />
          <ElementsButtonsIcon v-if="!isLand" :icon-src="bathrooms" :label="`${listing.bathrooms || '-'}`" not-clickable />
          <ElementsButtonsIcon v-if="!isLand" :icon-src="parkings" :label="`${listing.garageSpaces || '-'}`" not-clickable />
          <ElementsButtonsIcon :icon-src="landSize" :label="landOrFloorSizeLabel" not-clickable />
        </div>
      </div>

      <nuxt-link v-if="listing.isSold" :to="disableLink === false ? linkUrl : ''" @click="triggerAnalyticsClickEvent">
        <div class="__sold-text">
          <span>SOLD</span>
        </div>
      </nuxt-link>
    </div>
    <AdWidget v-else-if="listing && listing.category === 'Ad'" :listing="listing" />
    <div v-else :key="`listing-widget-loading`" class="blocks-listing" :class="`__${type}`">
      <div v-if="isLoading" class="skeleton-wrapper">
        <div class="skeleton-image" />
        <div class="skeleton-content">
          <div class="skeleton-title" />
          <div class="skeleton-address" />
          <div class="skeleton-builder-logo" />
          <div class="skeleton-icon-info">
            <div class="skeleton-icon" />
            <div class="skeleton-icon" />
            <div class="skeleton-icon" />
            <div class="skeleton-icon" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import beds from '~/assets/images/icons/beds.webp';
import parkings from '~/assets/images/icons/parkings.webp';
import bathrooms from '~/assets/images/icons/bathrooms.webp';
import landSize from '~/assets/images/icons/land-size.webp';
import {computed, ComputedRef, PropType, Ref} from 'vue';
import {ListingType, ListingWidget} from '~/@types/listing';
import {useListingStore} from '~/store/listing';
import {AnalyticsDataProps} from '~/@types/analytics';
import favoriteIcon from 'assets/images/icons/favorite.webp';
import favoriteSelectedIcon from 'assets/images/icons/favorite-selected.webp';
import {useAuthUserStore} from '~/store/authUser';
import numberFormat from '../../../composables/numberFormat';
import {propertyTypes} from '~/composables/propertyTypes';

const props = defineProps({
  type: {
    type: String as PropType<'listing' | 'design'>,
    default: 'listing',
  },
  listing: {
    type: [Object, null] as PropType<ListingWidget>,
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  topCornerLabel: {
    type: [String, Object, null] as PropType<string | {label: string; color: string;} | null>,
    default: null,
  },
  analyticsEventData: {
    type: [Object, null] as PropType<AnalyticsDataProps>,
    default: null,
  },
  disableLink: {
    type: Boolean,
    default: false,
  },
});

const listingStore = useListingStore();
const listingWidgetRef: Ref<HTMLElement | null> = ref(null);
const isSectionVisible: Ref<boolean> = ref(false);
const {$analyticsTrack, $metaPixelTrackEvent} = useNuxtApp();
let observer: Ref<IntersectionObserver | null> = ref(null);
const authUserStore = useAuthUserStore();

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  initViewTracker();
});

/**
 * @WATCHER - listing
 */
watch(() => props.listing, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    initViewTracker(true);
  }
});

/**
 * Get the listing type bg color
 */
function listingTypeBgColor (listingType: ListingType) {
  const listingTypeObj = propertyTypes.find((item) => item.value === listingType);
  return listingTypeObj ? listingTypeObj.color : '#5c52c6';
}

/**
 * Init view tracker
 *
 * @param forceRefresh
 */
function initViewTracker (forceRefresh = false) {
  if (forceRefresh) {
    isSectionVisible.value = false;
    // Stop observing the element when the component is unmounted
    if (listingWidgetRef.value && observer.value) {
      observer.value.unobserve(listingWidgetRef.value);
    }
  }
  // Only proceed if listingWidgetRef exists and Observer is supported
  if (listingWidgetRef.value && 'IntersectionObserver' in window) {
    observer.value = new IntersectionObserver((entries) => {
      // entries[0] is this component
      if (entries[0].isIntersecting && !isSectionVisible.value) {
        triggerViewTracking();
        isSectionVisible.value = true;
      }
    }, {
      threshold: 0.8, // adjust this value to control when the observer callback should be fired
    });

    // Start observing the element
    observer.value.observe(listingWidgetRef.value);
  }
}

/**
 * @_BEFORE_UNMOUNT Hook
 */
onBeforeUnmount(() => {
  // Stop observing the element when the component is unmounted
  if (listingWidgetRef.value && observer.value) {
    observer.value.unobserve(listingWidgetRef.value);
  }
});

/**
 * Check whether this section inside the view or not
 */
function triggerViewTracking () {
  // Track analytics event
  $analyticsTrack({
    action: 'view',
    entity: props.type === 'listing' ? 'Listing' : 'Design',
    property: props.type === 'listing' ? 'listing' : 'design',
    entitySlug: props.listing.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      userIntention: 'passive',
      component: 'listing widget',
      searchResultId: props.analyticsEventData?.searchResultId,
      position: props.analyticsEventData?.position,
    },
  });
  if (listingWidgetRef.value && observer.value) {
    observer.value.unobserve(listingWidgetRef.value);
  }
}

/**
 * Trigger analytics click event
 */
function triggerAnalyticsClickEvent () {

  // Track analytics event
  $analyticsTrack({
    action: 'click',
    entity: props.type === 'listing' ? 'Listing' : 'Design',
    property: props.type === 'listing' ? 'listing' : 'design',
    entitySlug: props.listing.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      userIntention: 'passive',
      component: 'listing widget',
      searchResultId: props.analyticsEventData?.searchResultId,
      position: props.analyticsEventData?.position,
    },
  });
}

/**
 * Set hovered listing slug
 *
 * @param isHovered
 */
function setHoveredListing (isHovered: boolean) {
  listingStore.setHoveredListingSlug(isHovered ? props.listing.slug : null);
}

const linkUrl: ComputedRef<string> = computed(() => {
  if (props.type === 'listing') {
    return `/listing/${props.listing?.slug}/view`;
  } else {
    return `/design/${props.listing?.slug}/view`;
  }
});

const displayAddress: ComputedRef<string | null> = computed(() => {
  if (props.listing.addressOverride) {
    return props.listing.addressOverride;
  } else if (props.listing.address) {
    return props.listing.address.formattedAddress;
  }
  return null;
});

/**
 * Add/Remove from favorite
 */
function toggleAddToFavorite () {
  const favorites = authUserStore.favorites;
  const did = props.listing.did ? props.listing.did : props.listing.id;
  if (favorites && favorites.length > 0 && did) {
    const index = favorites.findIndex((item) => item.did === did);
    if (index > -1) {
      authUserStore.setFavorites(favorites.filter((item) => item.did !== did));
    } else {
      authUserStore.setFavorites([...favorites, {
        type: props.type,
        did,
      }]);

      // Add to favorites Pixel event
      $metaPixelTrackEvent('AddToWishlist', {
        content_name: props.listing.name,
        content_category: listingTypes.value.join(', ') ?? 'Listing',
        content_ids: [props.listing.did],
        content_type: 'Listing',
      });
    }
  } else if (did) {
    authUserStore.setFavorites([{
      type: props.type,
      did,
    }]);
  }
}

/**
 * Check whether this listing is a favorite or not
 */
const isAFavorite: ComputedRef<boolean> = computed(() => {
  const favorites = authUserStore.favorites;
  if (favorites && favorites.length > 0) {
    const did = props.listing.did ? props.listing.did : props.listing.id;
    const index = favorites.findIndex((item) => item.did === did);
    return index > -1;
  }
  return false;
});

const listingTypes: ComputedRef<ListingType[]> = computed(() => {
  if (props.listing && props.listing.listingTypes) {
    if (Array.isArray(props.listing.listingTypes)) {
      return props.listing.listingTypes;
    } else if (Array.isArray((props.listing.listingTypes as any).data)) {
      return (props.listing.listingTypes as any).data;
    }
  }
  return [];
});

const isLand: ComputedRef<boolean> = computed(() => {
  if (listingTypes.value.length) {
    return listingTypes.value.includes('Land');
  }
  return false;
});

const landOrFloorSizeLabel: ComputedRef<string> = computed(() => {
  if (listingTypes.value.length && listingTypes.value.includes('Design')) {
    return `${(props.listing?.floorArea || '-')}㎡`;
  }
  return `${(props.listing.landArea || '-')}㎡`;
});
</script>

<style lang="scss">
.blocks-listing {
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 10%);
  overflow: hidden;
  scale: 1;
  transition: all 0.4s ease-out;
  background: white;
  max-width: 390px;
  width: 100%;

  &.--loading {
    background-image: linear-gradient(
        90deg,
        rgba(240, 240, 240, 1) 25%,
        rgba(255, 255, 255, 1) 50%,
        rgba(240, 240, 240, 1) 75%
    );
    background-size: 200% 50%;
    animation: shine 2s infinite ease;
  }

  .__top-corner-label {
    position: absolute;
    z-index: 1;
    background: rgb(var(--primary-light));
    padding: 4px 12px;
    width: fit-content;
    height: fit-content;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 1px 2px #00000078;
    color: #fff;
    font-size: 12px;
  }

  .__favorite-button-wrapper {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 12px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .el-avatar {
      .__el-avatar-inner {
        -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
        transition: all 0.4s ease-in-out;
      }
    }
  }

  .__price {
    font-style: italic;
    color: #868686;
  }

  &.--favorite {
    .__favorite-button-wrapper {
      opacity: 1 !important;

      .el-avatar {
        .__el-avatar-inner {
          -webkit-box-shadow: inset 0 0 8px 0 rgba(0,0,0,0.4);
          box-shadow: inset 0 0 5px 0 rgba(0,0,0,0.4);
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }

  .__listing-image-wrapper {
    width: 100%;
    height: 240px;
    border-radius: 12px 12px 0 0;
    position: relative;
    overflow: hidden;

    .__listing-image {
      scale: 1;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-color: rgba(92, 82, 198, 0.2);
      transition: background-size 2s ease-out, all 0.5s ease-in-out;
    }

    .__listing-type-wrapper {
      position: absolute;
      bottom: -5px;
      left: 2px;
      width: fit-content;
      display: flex;

      .__listing-type {
        min-width: fit-content;
        display: flex;
        flex-wrap: nowrap;
        padding: 5px 12px 8px;
        border-radius: 8px 8px 0 0;
        box-shadow: 0 -10px 12px -10px #000000;
        z-index: 2;
        color: rgb(var(--text-light));
        font-size: 14px;
        margin-right: 4px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10px;
      z-index: 1;
      bottom: 0;
      left: 0;
      background: rgb(138,138,138);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(255,255,255,0) 100%);
    }
  }

  .__listing-info-wrapper {
    padding: 16px;

    a {
      text-decoration: none;
    }

    .__listing-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgb(var(--text-default));
      text-decoration: none !important;
      font-size: 18px;
      font-weight: 500;
    }

    .__listing-address-wrapper {
      display: inline-flex;
      align-items: center;
      padding: 16px 0;
      height: 65px;

      .__listing-address-icon {
        width: 10px;
        height: auto;
      }

      .__listing-address-text {
        padding-left: 12px;
        font-size: 14px;
        color: rgb(var(--text-lighter-1));
      }
    }

    .__builder-logo-wrapper {
      height: 60px;
      display: flex;
      align-items: center;
      margin-top: 5px;

      img {
        max-width: 80px;
        max-height: 50px;
        height: auto;
        width: auto;
        scale: 1;
        transition: scale 0.2s ease-in-out;

        &:hover {
          scale: 1.1;
          transition: scale 0.2s ease-in-out;
        }
      }

    }

    .__listing-icon-info-block {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      flex-wrap: wrap;

      .el-icon-button {
        .__el-icon-button-inner {
          padding: 5px;

          img {
            width: 14px;
            height: 14px;
          }
        }

        .__icon-button-label {
          font-size: 13px;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 0 16px 8px rgba(var(--primary), 0.25);
    scale: 1.01;
    transition: all 0.4s ease-out;

    .__listing-image {
      scale: 1.1;
      transition: scale 10s ease-out;
    }

    .__favorite-button-wrapper {
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
    }
  }

  @media screen and (max-width: $grid-sm) {
    .__listing-info-wrapper {
      .__listing-icon-info-block {
        .el-icon-button {
          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }

    .__favorite-button-wrapper {
      opacity: 1;
    }
  }


  &.__design {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .__listing-info-wrapper {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .__listing-type {
      background: rgb(var(--accent));
      color: rgb(var(--text-light));
      bottom: 0 !important;
    }

    .__price {
      margin-top: 16px;
    }

    .__builder-logo-wrapper {
      margin-top: 16px;
    }
  }

  // Skeleton Wrapper
  // Define the keyframe animation
  @keyframes shine {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  .skeleton-wrapper {
    display: flex;
    flex-direction: column;

    .skeleton-image,
    .skeleton-title,
    .skeleton-address,
    .skeleton-builder-logo,
    .skeleton-icon {
      background-image: linear-gradient(
          90deg,
          rgba(224, 224, 224, 1) 25%,
          rgba(240, 240, 240, 1) 50%,
          rgba(224, 224, 224, 1) 75%
      );
      background-size: 200% 50%;
      animation: shine 2s infinite ease;
    }

    .skeleton-image {
      width: 100%;
      height: 240px;
      border-radius: 12px 12px 0 0;
    }

    .skeleton-content {
      padding: 20px;

      .skeleton-title,
      .skeleton-address {
        height: 16px;
        margin-bottom: 12px;
      }

      .skeleton-title {
        width: 60%;
      }

      .skeleton-address {
        width: 40%;
      }

      .skeleton-builder-logo {
        height: 50px;
        margin-bottom: 12px;
      }

      .skeleton-icon-info {
        display: flex;
        justify-content: space-between;

        .skeleton-icon {
          width: 20%;
          height: 16px;
        }
      }
    }
  }

  &.__sold {
    .__sold-text {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      width: 100%;
      height: 100%;
      z-index: 9;
      color: red;
      background: rgba(255, 255, 255, 0.3);
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);

      span {
        position: absolute;
        top: 27px;
        left: 8px;
        transform: rotate(-37deg);
        color: white;
        font-weight: 500;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: -65px;
      transform: rotate(-90deg);
      left: -26px;
      width: 0;
      height: 0;
      border-left: 120px solid transparent;
      border-right: 90px solid transparent;
      border-top: 160px solid #368900e0;
    }
  }
}
</style>
