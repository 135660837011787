import { defineStore } from 'pinia';

export interface AuthState {
  sessionToken: null | string;
  expireAt: null | string;
  hasTokenError: boolean;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    sessionToken: null,
    expireAt: null,
    hasTokenError: false,
  }),
  getters: {
    //
  },
  actions: {
    setSessionToken (tokenData: {token: string | null; expireAt: string | null}) {
      this.sessionToken = tokenData.token;
      this.expireAt = tokenData.expireAt;
      this.hasTokenError = !(this.sessionToken && this.expireAt);
    },
    setHasTokenError (hasError: boolean) {
      this.hasTokenError = hasError;
    },
  },
});
