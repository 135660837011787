<template>
  <div v-if="development" ref="developmentWidgetRef" class="blocks-development" :class="`${isAFavorite ? '--favorite': ''}`" @mouseenter="setHoveredListing(true)" @mouseleave="setHoveredListing(false)">
    <!-- Top Corner label -->
    <div v-if="topCornerLabel" class="__top-corner-label" :style="topCornerLabel && topCornerLabel.color ? `color: ${topCornerLabel.color};` : ''">
      {{ topCornerLabel && topCornerLabel.label ? topCornerLabel.label : topCornerLabel }}
    </div>

    <!-- Favorite button -->
    <div class="__favorite-button-wrapper">
      <ElementsAvatar :icon-src="isAFavorite ? favoriteSelectedIcon : favoriteIcon" icon-alt="Favorite icon" @click="toggleAddToFavorite();" />
    </div>

    <!-- Development Image -->
    <nuxt-link :to="`/developments/${development.slug}/view`" @click="triggerAnalyticsClickEvent">
      <div class="__development-image-wrapper">
        <div class="__development-image" :style="`background-image: url('${development.heroImageUrl}');`" />
      </div>
    </nuxt-link>

    <!-- Development Info -->
    <div class="__development-info-wrapper">
      <h5 class="__development-region">
        {{ development.region?.name }}
      </h5>
      <h4 class="__development-title">
        {{ development.name }}
      </h4>
      <p class="__development-description">
        {{ development.shortDescription }}
      </p>

      <!-- Development Action -->
      <div class="__action-wrapper">
        <ElementsButtonsRegular size="md" label="Learn More" text :button-link="`/developments/${development.slug}/view`" secondary @click="triggerAnalyticsClickEvent" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {DevelopmentWidget} from '~/@types/development';
import {ComputedRef, PropType, Ref} from 'vue';
import {useDevelopmentStore} from '~/store/development';
import favoriteIcon from 'assets/images/icons/favorite.webp';
import favoriteSelectedIcon from 'assets/images/icons/favorite-selected.webp';
import {useAuthUserStore} from '~/store/authUser';

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  development: {
    type: Object as PropType<DevelopmentWidget>,
    required: true,
  },
  topCornerLabel: {
    type: [String, Object, null] as PropType<string | {label: string; color: string;} | null>,
    default: null,
  },
  analyticsEventData: {
    type: [Object, null] as PropType<{searchResultId?: string | null; displayedLocation?: string; position?: number} | null>,
    default: null,
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const developmentStore = useDevelopmentStore();
const developmentWidgetRef: Ref<HTMLElement | null> = ref(null);
const isSectionVisible: Ref<boolean> = ref(false);
const {$analyticsTrack, $metaPixelTrackEvent} = useNuxtApp();
let observer: Ref<IntersectionObserver | null> = ref(null);
const authUserStore = useAuthUserStore();

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  initViewTracker();
});

/**
 * @_WATCHER - props.development
 */
watch(() => props.development, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    initViewTracker(true);
  }
});

/**
 * Trigger View Tracking
 *
 * @param forceRefresh
 */
function initViewTracker (forceRefresh = false) {
  if (forceRefresh) {
    // Stop observing the element when the component is unmounted
    if (developmentWidgetRef.value && observer.value) {
      observer.value.unobserve(developmentWidgetRef.value);
    }
  }
  // Only proceed if developmentWidgetRef exists and Observer is supported
  if (developmentWidgetRef.value && 'IntersectionObserver' in window) {
    observer.value = new IntersectionObserver((entries) => {
      // entries[0] is this component
      if (entries[0].isIntersecting && !isSectionVisible.value) {
        triggerViewTracking();
        isSectionVisible.value = true;
      }
    }, {
      threshold: 0.8, // adjust this value to control when the observer callback should be fired
    });

    // Start observing the element
    observer.value.observe(developmentWidgetRef.value);
  }
}

/**
 * @_BEFORE_UNMOUNT Hook
 */
onBeforeUnmount(() => {
  // Stop observing the element when the component is unmounted
  if (developmentWidgetRef.value && observer.value) {
    observer.value.unobserve(developmentWidgetRef.value);
  }
});

/**
 * Check whether this section inside the view or not
 */
function triggerViewTracking () {
  // Track analytics event
  $analyticsTrack({
    action: 'view',
    property: 'development',
    entity: 'Development',
    entitySlug: props.development.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      userIntention: 'passive',
      component: 'development widget',
      searchResultId: props.analyticsEventData?.searchResultId,
      position: props.analyticsEventData?.position,
    },
  });
  if (developmentWidgetRef.value && observer.value) {
    observer.value.unobserve(developmentWidgetRef.value);
  }
}

/**
 * Trigger analytics click event
 */
function triggerAnalyticsClickEvent () {
  // Track analytics event
  $analyticsTrack({
    action: 'click',
    property: 'development',
    entity: 'Development',
    entitySlug: props.development.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      userIntention: 'passive',
      component: 'development widget',
      searchResultId: props.analyticsEventData?.searchResultId,
      position: props.analyticsEventData?.position,
    },
  });
}

/**
 * Set hovered listing slug
 *
 * @param isHovered
 */
function setHoveredListing (isHovered: boolean) {
  developmentStore.setHoveredDevelopmentSlug(isHovered ? props.development.slug : null);
}

/**
 * Add/Remove from favorite
 */
function toggleAddToFavorite () {
  const favorites = authUserStore.favorites;
  const did = props.development.id ? props.development.id : props.development.did;
  if (favorites && favorites.length > 0) {
    const index = favorites.findIndex((item) => item.did === did);
    if (index > -1) {
      authUserStore.setFavorites(favorites.filter((item) => item.did !== did));
    } else {
      authUserStore.setFavorites([...favorites, {
        type: 'development',
        did: did,
      }]);

      // Add to favorites Pixel event
      $metaPixelTrackEvent('AddToWishlist', {
        content_name: props.development.name,
        content_category: props.development.type ?? 'Development',
        content_ids: [props.development.did],
        content_type: 'Development',
      });
    }
  } else {
    authUserStore.setFavorites([{
      type: 'development',
      did: did,
    }]);
  }
}

/**
 * Check whether this listing is a favorite or not
 */
const isAFavorite: ComputedRef<boolean> = computed(() => {
  const favorites = authUserStore.favorites;
  const did = props.development.id ? props.development.id : props.development.did;
  if (favorites && favorites.length > 0) {
    const index = favorites.findIndex((item) => item.did === did);
    return index > -1;
  }
  return false;
});
</script>

<style lang="scss">
.blocks-development {
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 10%);
  margin-bottom: 30px;
  width: 100%;
  max-width: 390px;
  overflow: hidden;
  scale: 1;
  transition: all 0.4s ease-out;

  .__top-corner-label {
    position: absolute;
    z-index: 1;
    background: rgb(var(--primary-light));
    padding: 4px 12px;
    width: fit-content;
    height: fit-content;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 1px 2px #00000078;
    color: #fff;
    font-size: 12px;
  }

  .__favorite-button-wrapper {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 12px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .el-avatar {
      .__el-avatar-inner {
        -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
        transition: all 0.4s ease-in-out;
      }
    }
  }

  &.--favorite {
    .__favorite-button-wrapper {
      opacity: 1 !important;

      .el-avatar {
        .__el-avatar-inner {
          -webkit-box-shadow: inset 0 0 8px 0 rgba(0,0,0,0.4);
          box-shadow: inset 0 0 5px 0 rgba(0,0,0,0.4);
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }

  .__development-image-wrapper {
    width: 100%;
    height: 240px;
    border-radius: 12px 12px 0 0;
    position: relative;
    overflow: hidden;

    .__development-image {
      scale: 1;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: background-size 2s ease-out, background-image 0.5s ease-in-out;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10px;
      z-index: 1;
      bottom: 0;
      left: 0;
      background: rgb(138,138,138);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(255,255,255,0) 100%);
    }
  }

  .__development-info-wrapper {
    padding: 20px;

    .__development-region {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
      font-weight: 500;
      color: rgb(var(--text-dark));
      text-transform: uppercase;
      font-size: 14px;
    }

    .__development-title {
      font-size: 18px;
      font-weight: 500;
      width: 100%;
      text-align: center;
    }

    .__development-description {
      font-weight: 300;
      font-size: 14px;
      text-align: center;
      padding: 20px 12px;
      color: rgba(var(--text-default), 0.5);
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 63px;
    }

    .__action-wrapper {
      width: 100%;
      text-align: center;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover {
    box-shadow: 0 0 16px 8px rgba(var(--primary), 0.25);
    scale: 1.01;
    transition: all 0.4s ease-out;

    .__development-image {
      scale: 1.1;
      transition: scale 10s ease-out;
    }

    .__favorite-button-wrapper {
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
    }
  }
}
</style>
