<template>
  <div ref="listingWidgetRef" class="blocks-ad-wrapper">
    <component :is="listing.adDestinationUrl ? 'a' : 'div'" v-if="listing" class="blocks-ad-link-wrapper" :href="listing.adDestinationUrl ? listing.adDestinationUrl : null" target="_blank" :style="`cursor: ${listing.adDestinationUrl ? 'pointer' : 'default'};`" @click="triggerAnalyticsClickEvent">
      <div :key="`ad-widget-item`" class="blocks-ad" :style="maxHeight ? `max-height: ${maxHeight}` : ''" @mouseenter="setHoveredListing(true)" @mouseleave="setHoveredListing(false)">
        <!-- Top Corner label -->
        <div class="__top-corner-label">
          Ad
        </div>

        <!-- Ad image -->
        <nuxt-img class="__ad-img" :src="listing.heroImageUrl" fit="cover" loading="lazy" :alt="`${listing.name} cover image`" />
      </div>
    </component>
  </div>
</template>

<script setup lang="ts">
import {PropType, Ref} from 'vue';
import {ListingWidget} from '~/@types/listing';
import {useListingStore} from '~/store/listing';
import {AnalyticsDataProps} from '~/@types/analytics';

const props = defineProps({
  listing: {
    type: [Object, null] as PropType<ListingWidget>,
    default: null,
  },
  analyticsEventData: {
    type: [Object, null] as PropType<AnalyticsDataProps>,
    default: null,
  },
  maxHeight: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
});

const listingStore = useListingStore();
const listingWidgetRef: Ref<HTMLElement | null> = ref(null);
const isSectionVisible: Ref<boolean> = ref(false);
let observer: Ref<IntersectionObserver | null> = ref(null);
const {$analyticsTrack} = useNuxtApp();

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  initViewTracker();
});

/**
 * @WATCHER - listing
 */
watch(() => props.listing, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    initViewTracker(true);
  }
});

/**
 * Init view tracker
 *
 * @param forceRefresh
 */
function initViewTracker (forceRefresh = false) {
  if (forceRefresh) {
    isSectionVisible.value = false;
    // Stop observing the element when the component is unmounted
    if (listingWidgetRef.value && observer.value) {
      observer.value.unobserve(listingWidgetRef.value);
    }
  }
  // Only proceed if listingWidgetRef exists and Observer is supported
  if (listingWidgetRef.value && 'IntersectionObserver' in window) {
    observer.value = new IntersectionObserver((entries) => {
      // entries[0] is this component
      if (entries[0].isIntersecting && !isSectionVisible.value) {
        triggerViewTracking();
        isSectionVisible.value = true;
      }
    }, {
      threshold: 0.8, // adjust this value to control when the observer callback should be fired
    });

    // Start observing the element
    observer.value.observe(listingWidgetRef.value);
    JSON.stringify(props.listing);
  }
}

/**
 * @_BEFORE_UNMOUNT Hook
 */
onBeforeUnmount(() => {
  // Stop observing the element when the component is unmounted
  if (listingWidgetRef.value && observer.value) {
    observer.value.unobserve(listingWidgetRef.value);
  }
});

/**
 * Set hovered listing slug
 *
 * @param isHovered
 */
function setHoveredListing (isHovered: boolean) {
  listingStore.setHoveredListingSlug(isHovered ? props.listing.slug : null);
}

/**
 * Check whether this section inside the view or not
 */
function triggerViewTracking () {
  // Track analytics event
  $analyticsTrack({
    action: 'view',
    entity: 'Listing',
    property: 'listing',
    entitySlug: props.listing.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      userIntention: 'passive',
      component: 'listing widget',
      searchResultId: props.analyticsEventData?.searchResultId,
      position: props.analyticsEventData?.position,
    },
  });
  if (listingWidgetRef.value && observer.value) {
    observer.value.unobserve(listingWidgetRef.value);
  }
}


/**
 * Trigger analytics click event
 */
function triggerAnalyticsClickEvent () {
  // Track analytics event
  $analyticsTrack({
    action: 'click',
    entity: 'Listing',
    property:'listing',
    entitySlug: props.listing.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      userIntention: 'passive',
      component: 'listing widget',
      searchResultId: props.analyticsEventData?.searchResultId,
      position: props.analyticsEventData?.position,
    },
  });
}
</script>

<style lang="scss">
.blocks-ad-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .blocks-ad-link-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 575px) {
    min-height: 472px;
  }
}

.blocks-ad {
  position: relative;
  border-radius: 12px;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 10%);
  overflow: hidden;
  scale: 1;
  transition: all 0.4s ease-out;
  background: white;
  max-width: 390px;
  width: 100%;
  height: 100%;
  display: flex;

  .__top-corner-label {
    position: absolute;
    z-index: 1;
    background: rgb(var(--accent));
    padding: 4px 12px;
    width: fit-content;
    height: fit-content;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 1px 2px #00000078;
    color: #fff;
    font-size: 12px;
  }

  .__ad-img {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0 0;
    position: relative;
    overflow: hidden;
    display: flex;
    scale: 1;
    object-fit: contain;
    background-color: rgba(92, 82, 198, 0.2);
    transition: object-fit 2s ease-out, all 0.5s ease-in-out;
    min-height: 100%;
    min-width: 100%;
  }

  &:hover {
    box-shadow: 0 0 16px 8px rgba(var(--primary), 0.25);
    scale: 1.01;
    transition: all 0.4s ease-out;

    .__ad-img {
      scale: 1.1;
      transition: scale 10s ease-out;
    }
  }
}
</style>
