import { defineStore } from 'pinia';
import {DevelopmentData} from '~/@types/development';

export interface FilterBy {
  field: string;
  value: string | null;
}

export type DevelopmentViewType = 'Map' | 'List' | 'Mobile-Map';
export interface DevelopmentState {
  developmentData: DevelopmentData;
  searchFields: string[];
  queryOptions: {
    searchKeyword: string | null
    filterBy: FilterBy[];
    page: number;
  };
  requestId: string | null;
  sortBy: string | null;
  viewType: DevelopmentViewType;
  hoveredItemSlug: string | null;
  hasUserChangedMapBounds: boolean;
  isLoading: boolean;
  urlParamObject: object | null,
}

export const useDevelopmentStore = defineStore('development', {
  state: (): DevelopmentState => ({
    developmentData: {
      found: 0,
      facet_counts: [],
      hits: [],
      out_of: 0,
      page: 0,
      request_params: {
        per_page: 0,
      },
    },
    searchFields: ['name', 'address', 'addressOverride', 'description', 'keyInfo'],
    queryOptions: {
      searchKeyword: null,
      filterBy: [],
      page: 1,
    },
    requestId: null,
    sortBy: null,
    hoveredItemSlug: null,
    hasUserChangedMapBounds: false,
    viewType: 'Map',
    isLoading: true,
    urlParamObject: null,
  }),
  getters: {
    //
  },
  actions: {
    setDevelopmentData (developmentData: any) {
      this.developmentData = developmentData;
    },
    setQueryOptions (queryOptions: {searchKeyword?: string | null; filterBy?: FilterBy[]}) {
      if (JSON.stringify(queryOptions) !== JSON.stringify(this.queryOptions)) {
        const {$development} = useNuxtApp();

        // Set search keyword
        if (typeof queryOptions.searchKeyword !== 'undefined') {
          this.queryOptions.searchKeyword = queryOptions.searchKeyword;
        }

        // Set filter by
        if (queryOptions.filterBy) {
          this.queryOptions.filterBy = queryOptions.filterBy;
        }

        this.queryOptions.page = 1;
        $development.search();
      }
    },
    setRequestId (requestId: string | null) {
      this.requestId = requestId;
    },
    setPage (page: number) {
      const {$development} = useNuxtApp();
      this.queryOptions.page = page;
      $development.search();
    },
    setHoveredDevelopmentSlug (slug: string | null) {
      this.hoveredItemSlug = slug;
    },
    setHasUserChangedMapBounds (hasUserChangedMapBounds: boolean) {
      this.hasUserChangedMapBounds = hasUserChangedMapBounds;
    },
    setViewType (viewType: DevelopmentViewType) {
      this.viewType = viewType;
    },
    setIsLoading (isLoading: boolean) {
      this.isLoading = isLoading;
    },
    setUrlParamObject (urlParamObject: object | null) {
      this.urlParamObject = urlParamObject;
    },
  },
});
