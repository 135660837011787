/**
 * Clou Auth Middleware
 * =====================================================================================================================
 * Provide a route middleware to authenticate the browser session with the server.
 *
 */
import {useCookie, useNuxtApp, useRuntimeConfig} from '#app';
import {useAuthStore} from '~/store/auth';

// Register middleware
export default async function () {
  await authenticateSession();
}

/**
 * Authenticate the session and generate the session token
 */
async function authenticateSession () {
  const nuxtApp = useNuxtApp();
  const authStore = useAuthStore();
  const runtimeConfig = useRuntimeConfig();

  // If the auth token exists, just return
  const authToken = authStore.sessionToken;
  if (authToken || process.client) {
    return;
  }

  const {apiSecret} = useRuntimeConfig();

  // Get the auth secret key
  if (!apiSecret) {
    if (typeof nuxtApp.$sentryCaptureException === 'function' && process.env.NUXT_APP_ENV === 'production') {
      // Trigger secret key not found error
      nuxtApp.$sentryCaptureException('NUXT_API_SECRET key not found! NUXT_API_SECRET is required to get a session token from the API server!');
    } else {
      console.error('NUXT_API_SECRET key not found! NUXT_API_SECRET is required to get a session token from the API server!');
    }
  }

  // Maintenance access key
  const key = useCookie('key');

  // Create headers
  const headers = new Headers([
    ['Accept', 'application/json'],
    ['Content-Type', 'application/json'],
    ['clientUid', String(runtimeConfig.public.clientUid)],
    ['Session-Token', String(authStore.sessionToken)],
    ['Access-Key', `${key.value}`],
  ]);

  try {
    // Make the api call to the server to authenticate the session
    const response = await $fetch(`${runtimeConfig.public.apiUrl}/auth-session`, {method: 'post', body: {secret: apiSecret}, headers});
    // Set token data
    if ((response as any).code === 200 && (response as any).data && (response as any).data.token) {
      authStore.setSessionToken((response as any).data);
      return;
    } else {
      (nuxtApp as any).$sentryCaptureException(new Error('Session key generation failed. Site may be down!'), {level: 'fatal'});
    }
  } catch (error) {
    (nuxtApp as any).$sentryCaptureException(new Error('Session token generation failed!'), {level: 'fatal', extra: {error}});
  }
}
