import {useAuthStore} from '~/store/auth';
import {useAuthUserStore} from '~/store/authUser';

export default (headers: [string, string][] = []) => {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();
  const authUserStore = useAuthUserStore();

  // Maintenance access key
  const key = useCookie('key');

  // If no maintenance access key, then the access key will be filled with a fake random key string
  const timestamp = new Date().getTime(); // get current timestamp
  const randomInt = Math.floor(Math.random() * 100000000000000); // generate a random integer
  const randomString = (timestamp + randomInt).toString(36); // convert to base 36 string

  return new Headers([
    ['Accept', 'application/json'],
    ['Client-Uid', String(config.public.clientUid) || String(config.clientUid)],
    ['Session-Token', String(authStore.sessionToken)],
    ['Access-Key', `${key.value || randomString}`],
    ['Authorization', `Bearer ${authUserStore.token}`],
    ...headers,
  ]);
};
