import {useRouter} from '#app';
import {TypesenseSearchParams} from '~/@types/common';

export interface DevelopmentSearchRouteQuery {
  keyword: string;
  developmentType: 'Sub-division' | 'Apartment' | '';
  mapBounds: string; // String or empty string if not selected example: '(northEast.lat, northEast.lng, northWest.lat, northWest.lng, southWest.lat, southWest.lng, southEast.lat, southEast.lng)'
  page: number; // Number as string or empty string if not selected
}

export interface DevelopmentSearchRouteQuerySetQuery {
  keyword?: string;
  developmentType?: 'Sub-division' | 'Apartment' | 'Terrace Homes' | '';
  mapBounds?: string; // String or empty string if not selected example: '(northEast.lat, northEast.lng, northWest.lat, northWest.lng, southWest.lat, southWest.lng, southEast.lat, southEast.lng)'
  page?: number; // Number as string or empty string if not selected
}

export default class DevelopmentSearchQueryHandler {
  public static defaultQuery: DevelopmentSearchRouteQuery = {
    keyword: '',
    developmentType: '',
    mapBounds: '',
    page: 1,
  };

  /**
   * Set development search route query
   *
   * @param setQuery
   */
  public static set (setQuery: DevelopmentSearchRouteQuerySetQuery) {
    const router = useRouter();
    if (!setQuery.page) {
      setQuery.page = 1;
    }
    router.push({path: '/developments/search', query: {...this.get(), ...setQuery}});
  }

  /**
   * Get development search route query
   */
  public static get (): DevelopmentSearchRouteQuery {
    const route = useRoute();
    return {...this.defaultQuery, ...route.query};
  }

  /**
   * Get development search typesense query data
   */
  public static getTypesenseQuery (): TypesenseSearchParams {
    const params = this.get();
    let typesenseFilterBy = '';

    if (params.mapBounds) {
      typesenseFilterBy += `location:${params.mapBounds}`;
    }
    if (params.developmentType) {
      if (typesenseFilterBy) typesenseFilterBy += ' && ';
      typesenseFilterBy += `type:=${params.developmentType}`;
    }

    return {
      keyword: params.keyword,
      filterBy: typesenseFilterBy,
      page: params.page,
    };
  }
}
