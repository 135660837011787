<template>
  <section ref="sectionCategorizedListingRef" class="sections-categorized-listing" :class="{'--visible': isSectionVisible}">
    <div class="container">
      <div class="row">
        <!-- Heading -->
        <div :class="showCategoryFilters ? `col-12 col-xl-4`: 'col-12'">
          <ElementsHeadingsDefault :heading="heading" :sub-heading="subHeading" />
        </div>

        <!-- Filter buttons -->
        <div v-if="showCategoryFilters" class="col-12 col-xl-8">
          <ElementsButtonsButtonGroup v-model="selectedFilter" :button-items="filteredFeaturedCategories" show-mobile-button-group :min-desktop-width="1200" />
        </div>
      </div>

      <!-- Featured Listing -->
      <div v-if="!customListingParams && featuredStore && featuredStore[selectedFilter]" class="row __listing-block-wrapper justify-content-center">
        <div v-for="(featuredItem, key) in selectedFeaturedItems" :key="`listing-widget-${key}`" class="col-md-6 col-lg-4 d-flex justify-content-center" style="margin-top: 28px;">
          <AdWidget v-if="featuredItem?.category === 'Ad'" :analytics-event-data="{displayedLocation: 'home_featured_widget'}" :listing="featuredItem" style="max-height: 100%; min-height: 420px;" />
          <ListingWidget v-else-if="['houseAndLand', 'lands', 'showhomes', 'designs'].includes(selectedFilter)" top-corner-label="Featured" :listing="featuredItem" :type="selectedFilter === 'designs' ? 'design' : 'listing'" :analytics-event-data="{displayedLocation: `Home page - featured ${selectedFilter}`, position: (key + 1) as number}" />
          <DevelopmentWidget v-else-if="selectedFilter === 'developments'" :development="featuredItem" top-corner-label="Featured" />
          <BuilderWidget v-else-if="selectedFilter === 'builders'" widget-type="Block" :builder="featuredItem" top-corner-label="Featured" :analytics-event-data="{displayedLocation: `Home page - featured ${selectedFilter}`, position: (key + 1) as number}" />
        </div>
      </div>

      <!-- Custom Listing -->
      <div v-else-if="customListings[selectedFilter].hits.length" class="row __listing-block-wrapper justify-content-center">
        <div v-for="(listing, key) in customListings[selectedFilter].hits" :key="`listing-widget-${key}`" class="col-md-6 col-lg-4 d-flex justify-content-center" style="margin-top: 28px;">
          <ListingWidget :listing="listing.document" :type="listing.document.listingTypes.includes('Design') ? 'design' : 'listing'" :analytics-event-data="{displayedLocation: displayLocation, position: (key + 1) as number}" />
        </div>
      </div>
      <div v-else>
        <p style="text-align: center; color: #808080; margin: 40px 0;">
          No Listings Found Under This Category!
        </p>
      </div>
      <div v-if="!customListingParams || (viewMoreCompanySlug && customListings[selectedFilter].currentPage < customListings[selectedFilter].lastPage)" class="row __load-more-button-wrapper">
        <ElementsButtonsRegular secondary size="md" :label="viewMoreLabel" @click="navigateToViewMore()" />
      </div>
      <div v-else-if="!viewMoreCompanySlug && customListingParams && customListings[selectedFilter].currentPage < customListings[selectedFilter].lastPage" class="row __load-more-button-wrapper">
        <ElementsButtonsRegular secondary :loading="isMoreLoading" size="md" label="Load More" @click="loadMore()" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {PropType, Ref} from 'vue';
import {useFeaturedStore} from '~/store/featured';
import {Listing, ListingCustomSearchParams, ListingHits, ListingType} from '~/@types/listing';
import ListingSearchQueryHandler from '~/composables/listingSearchQuery';
import {useNuxtApp} from '#app';

// DATA
const sectionCategorizedListingRef: Ref<HTMLElement | null> = ref(null);
const isSectionVisible: Ref<boolean> = ref(false);
const selectedFilter: Ref<string> = ref('houseAndLand');
const featuredStore = useFeaturedStore();
const isMoreLoading: Ref<boolean> = ref(false);
const router = useRouter();
const {$featured} = useNuxtApp();
const customListings: Ref<{[key:string]: {hits: ListingHits[], lastPage: number, currentPage: number}}> = ref({
  houseAndLand: {hits: [], lastPage: 1, currentPage: 1},
  lands: {hits: [], lastPage: 1, currentPage: 1},
  showhomes: {hits: [], lastPage: 1, currentPage: 1},
  designs: {hits: [], lastPage: 1, currentPage: 1},
});
const featuredCategories: Ref<{label: string; value: string}[]> = ref([
  {label: 'House & Land', value: 'houseAndLand'},
  {label: 'Land', value: 'lands'},
  {label: 'Showhome', value: 'showhomes'},
  {label: 'Design', value: 'designs'},
  {label: 'Builders', value: 'builders'},
  {label: 'Developments', value: 'developments'},
]);
const {$listing} = useNuxtApp();
const {$ad} = useNuxtApp();
const selectedAd: Ref<any | null> = ref(null);

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  heading: {
    type: String,
    default: 'Our Featured Selections',
  },
  subHeading: {
    type: String,
    default: 'You may be interested',
  },
  showCategoryFilters: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String as PropType<'listing' | 'design'>,
    default: 'listing',
  },
  customListingParams: {
    type: [Object, null] as PropType<ListingCustomSearchParams>,
    default: null,
  },
  viewMoreLink: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
  viewMoreCompanySlug: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
  viewMoreLabel: {
    type: String,
    default: 'View More',
  },
  hideViewMoreButton: {
    type: Boolean,
    default: false,
  },
  displayLocation: {
    type: String,
    default: 'Unknown',
  },
  disableLoadOnServer: {
    type: Boolean,
    default: false,
  },
  hiddenFilters: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

/**
 * @_SERVER_PREFETCH Hook
 */
onServerPrefetch(async () => {
  if (props.disableLoadOnServer === false) {
    if (!props.customListingParams) {
      await ($featured as any).set();
    } else {
      await getCustomListings();
    }
  }
});

fetchAd();

/**
 * Component MOUNTED!
 */
onMounted(async () => {
  document.addEventListener('scroll', checkIsInView);

  if (!props.customListingParams && !featuredStore.houseAndLand.length && !featuredStore.builders.length) {
    await ($featured as any).set();
  } else if (props.customListingParams && !customListings.value.length) {
    await getCustomListings();
  }
});

watch(() => selectedFilter.value, () => {
  if (props.customListingParams && !customListings.value[selectedFilter.value].hits.length) {
    if (!customListings.value[selectedFilter.value].hits.length) {
      getCustomListings();
    }
  }
});

/**
 * Get featured categories without hidden filters
 */
const filteredFeaturedCategories = computed(() => {
  return featuredCategories.value.filter((category) => !props.hiddenFilters.includes(category.value));
});

/**
 * Get custom listings
 */
async function getCustomListings (page = 1) {
  // Set custom listing params with filters
  let {filterBy} = props.customListingParams || '';
  if (filterBy) {
    const selectedFeaturedCategory = featuredCategories.value.find((category) => category.value === selectedFilter.value);
    if (selectedFeaturedCategory) {
      filterBy += ` && listingTypes:=[${selectedFeaturedCategory.label}]`;
    }
  }
  const listingResults = await ($listing as any).search(false, {...props.customListingParams, ...{filterBy}, ...{page}}, true);
  if (listingResults && listingResults.hits) {
    (customListings.value as any)[selectedFilter.value].hits = [...(customListings.value as any)[selectedFilter.value].hits, ...listingResults.hits];
    (customListings.value as any)[selectedFilter.value].lastPage = Math.ceil(listingResults.found / listingResults.request_params.per_page);
  }
}

/**
 * Check whether this section inside the view or not
 */
function checkIsInView () {
  if (sectionCategorizedListingRef.value && !isSectionVisible.value && isInView(sectionCategorizedListingRef.value, 200)) {
    isSectionVisible.value = true;
    document.removeEventListener('scroll', checkIsInView);
  }
}

/**
 * Navigate to view more page
 */
function navigateToViewMore () {
  if (props.viewMoreLink) {
    router.push(props.viewMoreLink);
  } else {
    const selectedFeaturedCategoryObject = featuredCategories.value.find((category) => category.value === selectedFilter.value);
    if (selectedFeaturedCategoryObject) {
      if (selectedFeaturedCategoryObject.label === 'Builders') {
        router.push('/builder/search');
      } else if (selectedFeaturedCategoryObject.label === 'Developments') {
        router.push('/developments/search');
      } else {
        let route = '/listing/search';
        if (props.viewMoreCompanySlug) {
          route = `/builder/${props.viewMoreCompanySlug}/listings`;
        }
        ListingSearchQueryHandler.set({
          listingTypes: selectedFeaturedCategoryObject.label ? ([selectedFeaturedCategoryObject.label as ListingType]) : [],
        }, route);
      }
    }
  }
}

/**
 * Load more listings
 */
async function loadMore () {
  (customListings.value as any)[selectedFilter.value].currentPage += 1;
  isMoreLoading.value = true;
  await getCustomListings((customListings.value as any)[selectedFilter.value].currentPage);
  isMoreLoading.value = false;
}

const selectedFeaturedItems = computed(() => {
  let returnData: Listing[] = JSON.parse((JSON.stringify(featuredStore[selectedFilter.value] || [])));
  if (featuredStore && returnData.length && selectedAd.value) {
    returnData.splice(2, 0, selectedAd.value);
  }
  return returnData.slice(0, 6) as Listing[];
});

/**
 * Fetch ad
 */
async function fetchAd () {
  try {
    const response = await $ad.search(['home_featured_widget']);
    if (response?.hits && response.hits.length) {
      const randomIndex = Math.floor(Math.random() * response.hits.length);
      selectedAd.value = response.hits[randomIndex].document as Listing;
    }
  } catch (error: any) {
    console.log(error);
  }
}
</script>

<style lang="scss" scoped>
.sections-categorized-listing {
  background: rgb(var(--background-light));

  .__listing-block-wrapper {
    padding: 10px 0 38px;
  }

  .__listing-block-wrapper > * {
    scale: 0.8;
    opacity: 0;
  }

  &.--visible {
    .__listing-block-wrapper > * {
      scale: 1;
      opacity: 1;
      transition: all 0.3s ease-in;
      @include delayTransition(0.1s, 6);
    }
  }

  .__load-more-button-wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>
