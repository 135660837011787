<template>
  <footer>
    <div class="footer-upper">
      <div class="container">
        <div class="row">
          <div class="__footer-column col-md-6 col-lg-3">
            <div class="row">
              <div class="col-md-12">
                <LayoutBrandLogo class="__footer-logo" />
              </div>
              <div class="col-md-12">
                <p class="__footer-quote">
                  We’re New Zealand's home for finding new homes – all in one place. Let us guide you through the process, from choosing a builder to finding a bank.
                </p>
              </div>
              <div class="col-md-12">
                <div class="__social-links">
                  <ElementsButtonsIcon :icon-src="facebookIcon" icon-alt="Facebook" icon-link="https://www.facebook.com/NZNewhomes/" link-target="_blank" />
                  <ElementsButtonsIcon :icon-src="youtubeIcon" icon-alt="Facebook" icon-link="https://www.youtube.com/channel/UCDmnliVLunCsfitSGI8fJgg" link-target="_blank" />
                  <ElementsButtonsIcon :icon-src="linkedinIcon" icon-alt="Facebook" icon-link="https://www.linkedin.com/company/18137434/" link-target="_blank" />
                  <ElementsButtonsIcon :icon-src="instagramIcon" icon-alt="Facebook" icon-link="https://www.instagram.com/nznewhomes/" link-target="_blank" />
                </div>
              </div>
            </div>
          </div>
          <div class="__footer-column col-md-6 col-lg-3">
            <BlocksTitledLinksList :links="northLinks" title="North Island" />
          </div>
          <div class="__footer-column col-md-6 col-lg-3">
            <BlocksTitledLinksList :links="southLinks" title="South Island" />
          </div>
          <div class="__footer-column col-md-6 col-lg-3">
            <BlocksTitledLinksList :links="lookingForLlinks" title="Looking For" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="__link-list-footer-column col-md-12 col-lg-6">
            <ul class="__link-list">
              {{ new Date().getFullYear() }} © All rights reserved
              <li class="__link-item">
                <nuxt-link to="/terms-of-use">
                  Terms of use
                </nuxt-link>
              </li>
              <li class="__link-item">
                <nuxt-link to="/privacy-policy">
                  Privacy Policy
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div class="col-md-12 col-lg-6 __author-quote">
            Design & Developed by
            <a href="https://www.clou.co.nz" target="_blank">
              Clou Digital
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import facebookIcon from  '~/assets/images/icons/facebook.webp';
import youtubeIcon from  '~/assets/images/icons/youtube.webp';
import linkedinIcon from  '~/assets/images/icons/linkedin.webp';
import instagramIcon from  '~/assets/images/icons/instagram.webp';
import {Ref} from 'vue';
import {Link} from '~/@types/common';

// DATA
const northLinks: Ref<Link[]> = ref([
  {label: 'Northland', url: '/listing/search?mapBounds=(-34.40006993581907,+175.04668066112106,+-34.40006993581907,+172.6468917989895,+-36.394557530575796,+172.6468917989895,+-36.394557530575796,+175.04668066112106)'},
  {label: 'Auckland', url: '/listing/search?mapBounds=(-36.254896426997476,+175.39743687981203,+-36.254896426997476,+173.92614596873443,+-37.45527453028868,+173.92614596873443,+-37.45527453028868,+175.39743687981203)'},
  {label: 'Hamilton', url: '/listing/search?mapBounds=(-37.45705907138363, 175.6550767750555, -37.45705907138363, 174.79933755542672, -38.14650418642427, 174.79933755542672, -38.14650418642427, 175.6550767750555)'},
  {label: 'Tauranga', url: '/listing/search?mapBounds=(-37.48576914208116, 176.32578363587032, -37.48576914208116, 175.82204484806635, -37.89223980496011, 175.82204484806635, -37.89223980496011, 176.32578363587032)'},
  {label: 'Hawke\'s Bay', url: '/listing/search?mapBounds=(-38.39842109904158, 178.04468777510596, -38.39842109904158, 175.98230365663494, -40.0276705165146, 175.98230365663494, -40.0276705165146, 178.04468777510596)'},
  {label: 'Wellington', url: '/listing/search?mapBounds=(-40.85217841707544, 175.3365585759156, -40.85217841707544, 174.534646861154, -41.46778921301548, 174.534646861154, -41.46778921301548, 175.3365585759156)'},
]);

const southLinks: Ref<Link[]> = ref([
  {label: 'Nelson', url: '/listing/search?mapBounds=(-41.115829895569604, 173.45381535634215, -41.115829895569604, 172.95573338140449, -41.49734328606878, 172.95573338140449, -41.49734328606878, 173.45381535634215)'},
  {label: 'Blenheim', url: '/listing/search?mapBounds=(-41.36276743591261, 174.10734941578642, -41.36276743591261, 173.71990772846632, -41.65860395964549, 173.71990772846632, -41.65860395964549, 174.10734941578642)'},
  {label: 'Christchurch', url: '/listing/search?mapBounds=(-43.12283689142492, 172.87243938821027, -43.12283689142492, 171.93486724745233, -43.816644775139864, 171.93486724745233, -43.816644775139864, 172.87243938821027)'},
  {label: 'Queenstown', url: '/listing/search?mapBounds=(-44.72213556010567, 169.34513956429186, -44.72213556010567, 168.2434612262207, -45.51480429740317, 168.2434612262207, -45.51480429740317, 169.34513956429186)'},
  {label: 'Cromwell', url: '/listing/search?mapBounds=(-44.84249296390546, 169.4786085821801, -44.84249296390546, 168.8911675566848, -45.265646806687386, 168.8911675566848, -45.265646806687386, 169.4786085821801)'},
  {label: 'Dunedin', url: '/listing/search?mapBounds=(-45.650468538694945, 170.6876716759616, -45.650468538694945, 170.09229652491925, -46.073237253901105, 170.09229652491925, -46.073237253901105, 170.6876716759616)'},
]);

const lookingForLlinks: Ref<Link[]> = ref([
  {label: 'Builders', url: '/builder/search'},
  {label: 'Builder Signup', url: 'https://www.newhomeshub.co.nz/auth/company-signup'},
  {label: 'Builder Login', url: 'https://www.newhomeshub.co.nz/auth/login'},
  {label: 'About', url: '/about'},
  {label: 'Contact', url: '/contact'},
  {label: 'Advertise with New Homes', url: '/contact?subject=Advertise with New Homes'},
]);
</script>
