/**
 * Clou Auth Middleware
 * =====================================================================================================================
 * Provide a route middleware to authenticate the browser session with the server.
 *
 * There are three authTypes:
 *  - [default]: Both authenticated and unauthenticated users can access the page.
 *  - guest: The page is accessible without authentication. Authenticated users are redirected to the home page.
 *  - auth: Only authenticated users can access the page. Unauthenticated users are redirected to the login page.
 *
 */
import {useAuthUserStore} from '~/store/authUser';

// Register middleware
export default defineNuxtRouteMiddleware((to) => {
  const authUserStore = useAuthUserStore();
  if (!to.meta.authType || to.meta.authType === 'common') {
    // Common page. So we don't fucking care.
    return;
  }

  const expireAt = authUserStore.expireAt ? new Date(authUserStore.expireAt) : null;
  const futureTime = new Date();
  futureTime.setMinutes(futureTime.getMinutes() + 5);

  if (authUserStore.$state.token && authUserStore.$state.user && expireAt && expireAt > futureTime) {
    if (to.meta.authType === 'guest') {
      // Auth user trying to access a guest page. So redirect these idiots to the home page.
      return navigateTo('/auth/my-account');
    }
  } else {
    if (to.meta.authType !== 'guest') {
      // Unauthenticated user trying to access an authenticated page. So redirect these mother fucking sneaky bitches to the login page.
      return navigateTo('/auth/login');
    }
  }
});
