<template>
  <div class="el-button-regular no-highlight" :class="`__${size} ${secondary === false ? '__primary' : '__secondary'} ${text !== false ? '__text' : ''} ${loading ? '--loading' : ''}`">
    <nuxt-link v-if="buttonLink" class="__button-inner" :to="buttonLink" :target="linkTarget || ''" @click="triggerAnalyticsTracking">
      <div v-if="appendIcon && !loading" class="__append-inner-icon">
        <img :src="appendIcon" alt="Action icon" data-not-lazy width="15" height="15">
      </div>
      <span v-if="!loading" class="__button-label">{{ label }}</span>

      <!-- Loading animation -->
      <div v-else v-html="loadingSvg" />
      <div v-if="prependIcon&& !loading" class="__prepend-inner-icon">
        <img :src="prependIcon" alt="Action icon" data-not-lazy>
      </div>
      <div v-else-if="secondary !== false" class="__prepend-inner-icon">
        <img :src="text !== false ? chevronDownDarkIcon : chevronDownIcon" alt="Action icon" data-not-lazy width="15" height="15">
      </div>
    </nuxt-link>

    <div v-else class="__button-inner" @click="triggerAnalyticsTracking">
      <div v-if="appendIcon && !loading" class="__append-inner-icon">
        <img :src="appendIcon" alt="Action icon" data-not-lazy width="15" height="15">
      </div>
      <span v-if="!loading" class="__button-label">{{ label }}</span>

      <!-- Loading animation -->
      <div v-else v-html="loadingSvg" />
      <div v-if="prependIcon && !loading" class="__prepend-inner-icon">
        <img :src="prependIcon" alt="Action icon" data-not-lazy width="15" height="15">
      </div>
      <div v-else-if="secondary !== false && prependIcon !== false" class="__prepend-inner-icon">
        <img :src="text !== false ? chevronDownDarkIcon : chevronDownIcon" alt="Action icon" data-not-lazy width="15" height="15">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {PropType} from 'vue';
import chevronDownIcon from '~/assets/images/icons/chevron-down-white.webp';
import chevronDownDarkIcon from '~/assets/images/icons/chevron-down.webp';
import {AnalyticsDataProps} from '~/@types/analytics';
import loadingIcon from '~/composables/loadingIcon';

// PROPS
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  buttonLink: {
    type: [String, Object],
    default: null,
  },
  linkTarget: {
    type: String as PropType<'_blank' | '_self' | '_parent' | '_top'>,
    default: '_self',
  },
  prependIcon: {
    type: [String, null, Boolean] as PropType<string | null | false>,
    default: null,
  },
  appendIcon: {
    type: String,
    default: null,
  },
  size: {
    type: String as PropType<'sm' | 'md' | 'lg'>,
    default: 'lg',
  },
  primary: {
    type: Boolean,
    default: true,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  text: {
    type: Boolean,
    default: false,
  },
  analyticsEventData: {
    type: [Object, null] as PropType<AnalyticsDataProps | null>,
    default: () => null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const {$analyticsTrack} = useNuxtApp();

// COMPUTED Properties
//----------------------------------------------------------------------------------------------------------------------
/**
 * Loading SVG
 */
const loadingSvg = computed(() => {
  const color = props.secondary === false ? '#56558e': '#fff';
  return loadingIcon(color);
});
/**
 * Trigger analytics tracking
 */
function triggerAnalyticsTracking () {
  if (!props.analyticsEventData || props.loading) return;

  // Track analytics event
  $analyticsTrack({
    action: 'click',
    property: props.analyticsEventData?.property,
    entity: props.analyticsEventData?.entity,
    entitySlug: props.analyticsEventData?.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      propertyType: props.analyticsEventData?.propertyType,
      userIntention: 'active',
      component: 'button',
      buttonAction: props.analyticsEventData?.buttonAction,
      buttonLabel: props.analyticsEventData?.buttonLabel || props.label,
      target: props.linkTarget,
    },
  });
}
</script>

<style lang="scss" scoped>
.el-button-regular {
  width: fit-content;
  padding: 12px 20px;
  background: rgba(var(--accent), 0.9);
  color: rgb(var(--text-light));
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  opacity: 0.95;
  box-shadow: 0 0 4px 1px #00000042;

  &.__secondary {
    background: rgb(var(--primary));
    border-radius: 8px;
    height: 28px;

    .__button-label {
      font-size: 14px;
      font-weight: 500;
    }

    .__prepend-inner-icon {
      margin-top: -3px;

      img {
        width: 14px;
        transform: rotate(-90deg);
      }
    }
  }

  &.__text {
    background-color: transparent;
    box-shadow: unset;
    color: rgb(var(--primary));
    padding: 12px 0;
    scale: 1;

    .__button-label {
      font-size: 14px;
      font-weight: 500;
      color: rgb(var(--primary));
    }

    &:hover {
      background: transparent;
      scale: 1.05;
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }

  .__button-inner {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    min-width: 80px;
    min-height: 20px;
    justify-content: center;

    .__button-loading-animation {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
    }
  }

  .__button-label {
    font-weight: 400;
    white-space: nowrap;
    color: inherit;
  }

  a {
    .__button-label {
      color: rgb(var(--text-light));
    }
  }

  .__prepend-inner-icon {
    margin-left: 8px;

    img {
      width: 15px;
      height: auto;
    }
  }

  .__append-inner-icon {
    margin-right: 1px;

    img {
      width: 15px;
      min-width: 12px;
      height: auto;
      margin-right: 8px;
    }
  }

  &:hover, &:active {
    background: rgba(var(--accent), 1);
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  &:active {
    opacity: 0.9;
  }

  &.__lg {
    .__button-inner {
      .__button-label {
        text-transform: uppercase;
      }

      .__append-inner-icon img,
      .__prepend-inner-icon img {
        width: 15px;
      }
    }
  }

  &.__sm {
    padding: 6px 8px;
    border-radius: 5px;

    .__button-inner {
      .__button-label {
        font-size: 12px;
      }

      .__prepend-inner-icon {
        width: 12px;
        height: fit-content;

        img {
          width: 12px;
        }
      }
    }
  }

  transition: all 0.2s ease-in-out;
}
</style>
