import {io, Socket} from 'socket.io-client';
import {useAuthUserStore} from '~/store/authUser';
import {useRuntimeConfig} from '#app';

let socket: Socket | null = null;

/**
 * Initiate the web socket
 */
function initSocket () {
  if (!socket) {
    const authUserStore = useAuthUserStore();

    if (authUserStore.token && authUserStore.user) {
      const config = useRuntimeConfig();
      socket = io(config.public.apiUrl, {
        transports: ['websocket'],
        auth: {
          token: authUserStore.token,
        },
        secure: true,
      });

      socket.on('chat-messages', (data: any) => {
        authUserStore.pushMessageToAThread(data.guid, data.message);
      });
    }
  }

  return socket;
}

export default defineNuxtPlugin((NuxtApp) => {
  return {
    provide: {
      initSocket: () => initSocket(),
    },
  };
});
