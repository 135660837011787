<template>
  <div v-if="builder" ref="builderWidgetRef" class="builder-widget" :class="widgetType.replaceAll(' ', '-')" :style="`max-width: ${maxWidth}`">
    <!-- Logo View -->
    <div v-if="widgetType === 'Logo'" class="builder-hover-effect">
      <!-- Show builder child companies -->
      <span v-if="haveChildren" style="cursor: pointer;" @click="isChildrenPopupShowing = true; triggerAnalyticsClickEvent()">
        <img :src="builder.logoUrl" :alt="`${builder.displayName || builder.name} logo`" data-not-lazy>
      </span>

      <!-- Open Builder Profile -->
      <nuxt-link v-else :to="`/builder/${builder.slug}/view`" @click="triggerAnalyticsClickEvent">
        <img :src="builder.logoUrl" :alt="`${builder.displayName || builder.name} logo`" data-not-lazy>
      </nuxt-link>
    </div>

    <!-- Info View -->
    <div v-else-if="widgetType === 'Info raw'" class="builder-widget__info-view">
      <div class="row">
        <div class="__builder-logo col-12 col-md-3 col-lg-2">
          <nuxt-link :to="`/builder/${builder.slug}/view`" @click="triggerAnalyticsClickEvent">
            <img :src="builder.logoUrl" :alt="`${builder.displayName || builder.name} logo`" data-not-lazy>
          </nuxt-link>
        </div>
        <div class="col-12 col-md-3 col-lg-3">
          <div class="__builder-name-and-ownership">
            <nuxt-link :to="`/builder/${builder.slug}/view`" @click="triggerAnalyticsClickEvent">
              <h3>{{ builder.displayName || builder.name }}</h3>
              <span class="__builder-ownership">{{ builder.ownershipStructure }}</span>
            </nuxt-link>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-12">
          <div class="__builder-services">
            <span class="__builder-services-label">Services: </span>
            <ul>
              <li v-for="(service, key) in builder.services" :key="`builder-service-${key}`" class="__builder-service">
                {{ service }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-3 col-lg-4">
          <div class="__builder-warranty">
            <span class="__builder-warranty-label">Warranty: </span>
            {{ builder.warranty }}
          </div>
        </div>
      </div>
    </div>

    <!-- Block View -->
    <div v-else class="blocks-builder" :class="`${isAFavorite ? '--favorite': ''}`">
      <!-- Top Corner label -->
      <div v-if="topCornerLabel" class="__top-corner-label" :style="topCornerLabel && topCornerLabel.color ? `color: ${topCornerLabel.color};` : ''">
        {{ topCornerLabel && topCornerLabel.label ? topCornerLabel.label : topCornerLabel }}
      </div>

      <!-- Favorite button -->
      <div class="__favorite-button-wrapper">
        <ElementsAvatar :icon-src="isAFavorite ? favoriteSelectedIcon : favoriteIcon" icon-alt="Favorite icon" @click="toggleAddToFavorite();" />
      </div>

      <!-- Builder Image -->
      <nuxt-link :to="`/builder/${builder.slug}/view`" @click="triggerAnalyticsClickEvent">
        <div class="__builder-image-wrapper">
          <div class="__builder-image" :style="`background-image: url('${builder.bannerUrl}');`" />
        </div>
      </nuxt-link>
      <div class="__builder-logo-wrapper">
        <nuxt-link :to="`/builder/${builder.slug}/view`" @click="triggerAnalyticsClickEvent">
          <img :src="builder.logoUrl" alt="builder logo" width="125" height="125" data-not-lazy>
        </nuxt-link>
      </div>

      <!-- Builder Info -->
      <div class="__builder-info-wrapper">
        <h5 v-if="builder.ownershipStructure" class="__builder-region">
          {{ builder.ownershipStructure }}
        </h5>
        <h4 class="__builder-title">
          {{ builder.name }}
        </h4>
        <div v-if="builder && builder.services && (Array.isArray(builder.services) || Array.isArray(builder.services.data))" class="__builder-description">
          {{ builder.services && builder.services.data ? builder.services.data.join(', ') : builder.services.join(', ') }}
        </div>

        <!-- Builder Action -->
        <div class="__action-wrapper">
          <ElementsButtonsRegular size="md" label="View Builder Profile" text :button-link="`/builder/${builder.slug}/view`" secondary @click="triggerAnalyticsClickEvent" />
        </div>
      </div>
    </div>

    <!-- Builder Children Popup -->
    <div v-if="haveChildren" class="builder-widget__children-popup-wrapper" :class="{'--open': isChildrenPopupShowing}">
      <div class="popup-header">
        <div class="parent-logo">
          <img :src="builder.logoUrl" :alt="`${builder.displayName || builder.name} logo`" data-not-lazy>
        </div>
        <ElementsButtonsRegular size="md" label="Close" text secondary :prepend-icon="false" @click="isChildrenPopupShowing = false" />
      </div>
      <div class="builders-wrapper">
        <BuilderWidget v-for="(child, key) in builder.childCompanies" :key="`child-${key}`" widget-type="Block" :builder="child" :analytics-event-data="{displayedLocation: 'Builders page', position: (key + 1) as number}" />
      </div>
    </div>
    <div v-if="isChildrenPopupShowing" class="backdrop" @click="isChildrenPopupShowing = false" />
  </div>
</template>

<script setup lang="ts">
import {ComputedRef, PropType, Ref} from 'vue';
import {AnalyticsDataProps} from '~/@types/analytics';
import favoriteIcon from 'assets/images/icons/favorite.webp';
import closeIcon from 'assets/images/icons/close.webp';
import favoriteSelectedIcon from 'assets/images/icons/favorite-selected.webp';
import {useAuthUserStore} from '~/store/authUser';

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  builder: {
    type: [Object, null] as PropType<any | null>,
    default: null,
  },
  topCornerLabel: {
    type: [String, Object, null] as PropType<string | {label: string; color: string;} | null>,
    default: null,
  },
  widgetType: {
    type: String as PropType<'Logo' | 'Info raw' | 'Block'>,
    default: 'Logo',
  },
  analyticsEventData: {
    type: [Object, null] as PropType<AnalyticsDataProps>,
    default: null,
  },
  maxWidth: {
    type: [String, Number] as PropType<string | number>,
    default: '390px',
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const builderWidgetRef: Ref<HTMLElement | null> = ref(null);
const isSectionVisible: Ref<boolean> = ref(false);
const {$analyticsTrack, $metaPixelTrackEvent} = useNuxtApp();
let observer: Ref<IntersectionObserver | null> = ref(null);
const authUserStore = useAuthUserStore();
const isChildrenPopupShowing: Ref<boolean> = ref(false);

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  initViewTracker();
});

/**
 * @_WATCH Builder
 */
watch(() => props.builder, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    initViewTracker(true);
  }
});

/**
 * Init view tracker
 *
 * @param forceRefresh
 */
function initViewTracker (forceRefresh = false) {
  if (forceRefresh) {
    // Stop observing the element when the component is unmounted
    if (builderWidgetRef.value && observer.value) {
      observer.value.unobserve(builderWidgetRef.value);
    }
  }
  // Only proceed if builderWidgetRef exists and Observer is supported
  if (builderWidgetRef.value && 'IntersectionObserver' in window) {
    observer.value = new IntersectionObserver((entries) => {
      // entries[0] is this component
      if (entries[0].isIntersecting && !isSectionVisible.value) {
        triggerViewTracking();
        isSectionVisible.value = true;
      }
    }, {
      threshold: 0.8, // adjust this value to control when the observer callback should be fired
    });

    // Start observing the element
    observer.value.observe(builderWidgetRef.value);
  }
}

/**
 * @_BEFORE_UNMOUNT Hook
 */
onBeforeUnmount(() => {
  // Stop observing the element when the component is unmounted
  if (builderWidgetRef.value && observer.value) {
    observer.value.unobserve(builderWidgetRef.value);
  }
});

/**
 * Check whether this section inside the view or not
 */
function triggerViewTracking () {
  // Track analytics event
  $analyticsTrack({
    action: 'view',
    property: 'builder',
    entity: 'Company',
    entitySlug: props.builder.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      propertyType: props.widgetType,
      userIntention: 'passive',
      component: 'builder widget',
      searchResultId: props.analyticsEventData?.searchResultId,
      position: props.analyticsEventData?.position,
    },
  });

  if (builderWidgetRef.value && observer.value) {
    observer.value.unobserve(builderWidgetRef.value);
  }
}

/**
 * Trigger analytics click event
 */
function triggerAnalyticsClickEvent () {
  // Track analytics event
  $analyticsTrack({
    action: 'click',
    property: 'builder',
    entity: 'Company',
    entitySlug: props.builder.slug,
    displayedLocation: props.analyticsEventData?.displayedLocation,
    eventData: {
      propertyType: props.widgetType,
      userIntention: 'active',
      component: 'builder widget',
      searchResultId: props.analyticsEventData?.searchResultId,
      position: props.analyticsEventData?.position,
    },
  });
}

/**
 * Add/Remove from favorite
 */
function toggleAddToFavorite () {
  const favorites = authUserStore.favorites;
  const did = props.builder.id ? props.builder.id : props.builder.did;
  if (favorites && favorites.length > 0) {
    const index = favorites.findIndex((item) => item.did === did);
    if (index > -1) {
      authUserStore.setFavorites(favorites.filter((item) => item.did !== did));
    } else {
      authUserStore.setFavorites([...favorites, {
        type: 'builder',
        did: did,
      }]);
    }

    // Add to favorites Pixel event
    $metaPixelTrackEvent('AddToWishlist', {
      content_name: props.builder.name,
      content_category: props.builder?.profileType ?? 'Builder',
      content_ids: [props.builder.did],
      content_type: 'Company',
    });
  } else {
    authUserStore.setFavorites([{
      type: 'builder',
      did: did,
    }]);
  }
}

/**
 * Check has children
 */
const haveChildren: ComputedRef<boolean> = computed(() => {
  return props.builder.childCompanies && props.builder.childCompanies.length > 0;
});

/**
 * Check whether this listing is a favorite or not
 */
const isAFavorite: ComputedRef<boolean> = computed(() => {
  const did = props.builder.id ? props.builder.id : props.builder.did;
  const favorites = authUserStore.favorites;
  if (favorites && favorites.length > 0) {
    const index = favorites.findIndex((item) => item.did === did);
    return index > -1;
  }
  return false;
});
</script>

<style lang="scss">
.builder-widget {
  width: 100%;
  max-width: 390px;

  &.Info-raw {
    max-width: unset;
  }

  @media screen and (max-width: $grid-sm) {
    &__info-view {
      .__builder-name-and-ownership {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-top: 12px;
      }
      .__builder-services {
        display: flex;
        justify-content: left !important;
        align-items: baseline !important;
        width: 100%;
        padding-left: 20px;
        margin-top: 12px;

        ul {
          display: flex;
          padding: 12px 0 !important;
          justify-content: space-between;
          flex-direction: column;

          li {
            list-style-type: square;
            color: #838383 !important;
            &:not(:last-child) {
              margin-right: 28px;
            }
          }
        }

        .__builder-services-label {
          display: block;
          margin-right: 32px;
          color: rgb(var(--primary-dark));
        }
      }

      .__builder-warranty {
        color: #b7b7b7 !important;
        display: flex;
        justify-content: left !important;
        margin-bottom: 12px;
        padding: 16px 20px;
        text-align: left;

        .__builder-warranty-label {
          display: block;
          margin-right: 12px;
          color: rgb(var(--primary-dark));
        }
      }
    }
  }

  .builder-hover-effect {
    transition: all 0.4s ease-out;

    &:hover {
      scale: 1.05;
      transition: all 0.4s ease-out;
    }
  }

  .blocks-builder {
    cursor: pointer;
    position: relative;
    border-radius: 12px;
    box-shadow: 0 0 15px 5px rgb(0 0 0 / 10%);
    overflow: hidden;
    scale: 1;
    transition: all 0.4s ease-out;

    .__top-corner-label {
      position: absolute;
      z-index: 1;
      background: rgb(var(--primary-light));
      padding: 4px 12px;
      width: fit-content;
      height: fit-content;
      border-bottom-right-radius: 5px;
      box-shadow: 1px 1px 2px #00000078;
      color: #fff;
      font-size: 12px;
    }

    .__favorite-button-wrapper {
      position: absolute;
      right: 12px;
      z-index: 1;
      top: 12px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      .el-avatar {
        .__el-avatar-inner {
          -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
          box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);
          transition: all 0.4s ease-in-out;
        }
      }
    }

    &.--favorite {
      .__favorite-button-wrapper {
        opacity: 1 !important;

        .el-avatar {
          .__el-avatar-inner {
            -webkit-box-shadow: inset 0 0 8px 0 rgba(0,0,0,0.4);
            box-shadow: inset 0 0 5px 0 rgba(0,0,0,0.4);
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }

    .__builder-image-wrapper {
      width: 100%;
      height: 240px;
      border-radius: 12px 12px 0 0;
      position: relative;
      overflow: hidden;

      .__builder-image {
        scale: 1;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        transition: background-size 2s ease-out, background-image 0.5s ease-in-out;
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 10px;
        z-index: 1;
        bottom: 0;
        left: 0;
        background: rgb(138,138,138);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(255,255,255,0) 100%);
      }
    }

    .__builder-logo-wrapper {
      position: absolute;
      overflow: hidden;
      background: #ffffffad;
      backdrop-filter: blur(3px);
      border: 2px solid #fff;
      width: 125px;
      height: 125px;
      border-radius: 50%;
      padding: 14px;
      top: 160px;
      z-index: 1;
      left: 0;
      box-shadow: 0 0 8px 0 #00000040;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }

    .__builder-info-wrapper {
      margin-top: 38px;
      padding: 20px;

      .__builder-region {
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
        font-weight: 500;
        color: rgb(var(--text-dark));
        text-transform: uppercase;
        font-size: 12px;
      }

      .__builder-title {
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        text-align: center;
        text-transform: capitalize;
      }

      .__builder-description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 43px;
        overflow: hidden;
        font-weight: 300;
        font-size: 14px;
        text-align: center;
        margin-top: 12px;
        padding: 0 12px;
        color: rgba(var(--text-default), 0.5);
      }

      .__action-wrapper {
        width: 100%;
        text-align: center;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover {
      box-shadow: 0 0 16px 8px rgba(var(--primary), 0.25);
      scale: 1.01 !important;
      transition: all 0.4s ease-out;

      .__builder-image {
        scale: 1.1;
        transition: scale 10s ease-out;
      }

      .__favorite-button-wrapper {
        opacity: 1;
        transition: opacity 0.4s ease-in-out;
      }
    }
  }

  .__builder-services-label, .__builder-warranty-label {
    display: none;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99;
  }

  .builder-widget__children-popup-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    scale: 0;
    background: #fff;
    box-shadow: 1px 1px 8px 0 #00000040;
    border-radius: 12px;
    padding: 20px 12px 0 12px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 80%;
    min-width: fit-content;
    min-height: fit-content;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, scale 0.3s 0.2s ease-in-out;

    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .parent-logo {
        margin-bottom: 20px;
        padding-left: 12px;

        img {
          max-width: 200px;
          max-height: 50px;
        }
      }
    }

    .builders-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      flex-wrap: wrap;
      overflow-y: auto;

      .builder-widget {
        min-width: 320px;
        max-width: 320px;
        margin: 0 12px;
      }
    }

    &.--open {
      scale: 1;
      opacity: 1;
      transition: opacity 0.2s 0.2s ease-in-out, scale 0.3s ease-in-out;
    }
  }
}
</style>
