<template>
  <div id="__clou-default-layout" class="clou-default-layout">
    <LayoutHeader />
    <main>
      <div v-if="error.statusCode === 404" class="page-error d-flex align-items-center flex-column">
        <img v-lazy-load  :data-src="pageNotFound" alt="Under Maintenance" width="400px" height="auto">
        <h1>Oops! Page not found.</h1>
        <p>Sorry, the page you're looking for appears to be invalid or is no longer available. Please head back to our homepage to continue exploring. Alternatively, you can check out our featured sections below.</p>

        <div class="row __go-home-button-wrapper">
          <ElementsButtonsRegular button-link="/" secondary size="md" label="Go Home" />
        </div>

        <SectionsListingCategorized />
      </div>
      <div v-else class="page-error d-flex align-items-center flex-column">
        <img v-lazy-load  :data-src="underMaintenanceImage" alt="Under Maintenance" width="400px" height="auto">
        <h1>We will be back soon!</h1>
        <p>We apologize for the inconvenience. Our website is currently undergoing maintenance to improve your user experience. We'll be back online shortly. Thank you for your patience.</p>
      </div>
    </main>
    <LayoutFooter />
  </div>
</template>

<script setup lang="ts">
import {PropType} from 'vue';
import {NuxtError} from '#app';
import underMaintenanceImage from '~/assets/images/backgrounds/webmaintenance.webp';
import pageNotFound from '~/assets/images/backgrounds/404-page.webp';

// Props
const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
});

/**
 * Component MOUNTED!
 */
onMounted(() => {
  //
});
</script>

<style lang="scss">
body {
  .page-error {
    background: #fff;
    padding: 60px 12px;
    min-height: 600px;

    h1 {
      font-size: 38px;
      font-weight: 700;
      padding: 60px 20px 20px;
      text-align: center;
      color: rgb(var(--text-dark));
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    }

    p {
      max-width: 720px;
      text-align: center;
    }

    .__go-home-button-wrapper {
      margin-top: 20px;
    }

    .sections-categorized-listing {
      border-top: 1px solid #e1e1e1;
      margin-top: 40px;
      max-width: 100%;
    }
  }
}
</style>
