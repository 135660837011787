<template>
  <div class="blocks-slider no-highlight" @mouseenter="isMouseOver = true;" @mouseleave="isMouseOver = false;">
    <!-- Go back button -->
    <div v-if="totalSliders > 1" class="__slider-go-back __slider-nav" @click="changeSlider(false)">
      <img v-lazy-load  :data-src="arrowIcon" alt="Go back icon" width="15" height="25">
    </div>

    <!-- Slider Items -->
    <div ref="sliderItemsRef" class="__slider-items">
      <slot />
    </div>

    <!-- Go next button -->
    <div v-if="totalSliders > 1" class="__slider-go-forward __slider-nav" @click="changeSlider(true)">
      <img v-lazy-load  :data-src="arrowIcon" alt="Go forward icon" width="15" height="25">
    </div>
  </div>
</template>

<script setup lang="ts">
import arrowIcon from '~/assets/images/icons/chevron-down.webp';
import {Ref} from 'vue';

// DATA
const sliderItemsRef: Ref<HTMLElement | null> = ref(null);
const isMouseOver: Ref<boolean> = ref(false);
const activeSlider: Ref<number> = ref(0);
const totalSliders: Ref<number> = ref(0);
const touchStarted: Ref<{x: number; y: number}> = ref({
  x: 0,
  y: 0,
});

// PROPS
const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  rotateSlides: {
    type: Boolean,
    default: false,
  },
  displayLengthInSeconds: {
    type: Number,
    default: 5,
  },
  stopRotationOnHover: {
    type: Boolean,
    default: true,
  },
});

// EMIT Definitions
const emit = defineEmits<{
  (event: 'swipeDown'): void;
  (event: 'update:modelValue', payload: number): any;
}>();

/**
 * Component MOUNTED!
 */
onMounted(() => {
  initSlider();
});

watch(() => props.modelValue, () => {
  if (props.modelValue !== activeSlider.value) {
    activeSlider.value = props.modelValue;
  }
});

watch(() => activeSlider.value, () => {
  setSlider();
  emit('update:modelValue', activeSlider.value);
});

/**
 * Initiate the slider
 */
function initSlider () {
  if (sliderItemsRef.value) {
    totalSliders.value = sliderItemsRef.value.childElementCount;

    if (totalSliders.value > 0) {
      activeSlider.value = 1;
    }

    // Set up the touch events
    document.addEventListener('touchstart', touchStared);
    document.addEventListener('touchend', touchEnded);

    // Set rotate animation
    if (props.rotateSlides !== false && totalSliders.value > 0) {
      rotateSlides();
    }
  }
}

/**
 * Change the active slider
 *
 * @param goNext
 */
function changeSlider (goNext: boolean) {
  if (goNext) {
    activeSlider.value = (activeSlider.value + 1) > totalSliders.value ? 1 : activeSlider.value + 1;
  } else {
    activeSlider.value = (activeSlider.value - 1) < 1 ? totalSliders.value : activeSlider.value - 1;
  }
  setSlider();
}

/**
 * Set slider
 */
function setSlider () {
  if (sliderItemsRef.value) {
    for (const sliderItem of sliderItemsRef.value.children) {
      (sliderItem as HTMLElement).style.transform = `translate(${(activeSlider.value - 1) * -100}%, 0)`;
    }
  }
}

/**
 * When touch started
 *
 * @param event
 */
function touchStared (event: TouchEvent) {
  touchStarted.value.x = event.changedTouches[0].screenX;
  touchStarted.value.y = event.changedTouches[0].screenY;
}

/**
 * When touch ended
 *
 * @param event
 */
function touchEnded (event: TouchEvent) {
  // Change images (Prev - Next)
  const touchEndedX = event.changedTouches[0].screenX;
  const swipeXRange = touchStarted.value.x - touchEndedX;
  if (Math.abs(swipeXRange) > 50) {
    changeSlider(swipeXRange > 0);
  }

  // // Close slider when swipe down
  // const touchEndedY = event.changedTouches[0].screenY;
  // const swipeYRange = touchStarted.value.y - touchEndedY;
  //
  // if (swipeXRange < 20 && Math.abs(swipeYRange) > 50) {
  //   emit('swipeDown');
  // }
}

/**
 * Rotate the slides
 */
function rotateSlides () {
  setTimeout(() => {
    if (!isMouseOver.value || props.stopRotationOnHover !== true) {
      changeSlider(true);
    }
    rotateSlides();
  }, props.displayLengthInSeconds * 1000);
}
</script>

<style lang="scss">
// Slider
.blocks-slider {
  width: 100%;
  height: 100%;
  position: relative;

  .__slider-items {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    align-items: center;

    &>* {
      min-width: 100%;
      height: 100%;
      transition: all 0.4s cubic-bezier(.770,0,.175,1);
    }

    .floor-plan-item {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .__image-wrapper {
        width: 100%;
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .__image-caption {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        color: rgba(var(--text-default), 0.4);
      }
    }
  }

  .__slider-nav {
    position: absolute;
    z-index: 9;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 20px 0;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;

    &.--disabled {
      cursor: default;
    }

    img {
      width: 25px;
      height: auto;
    }

    &.__slider-go-back {
      left: -20px;

      img {
        transform: rotate(90deg) translate(0, 0);
        transition: transform 0.2s ease-in-out;
      }

      &:hover:not(.--disabled) {
        img {
          transform: rotate(90deg) translate(0, 5px);
          transition: transform 0.2s ease-in-out;
        }
      }
    }

    &.__slider-go-forward {
      right: -20px;

      img {
        transform: rotate(-90deg) translate(0, 0);
        transition: transform 0.2s ease-in-out;
      }


      &:hover:not(.--disabled) {
        img {
          transform: rotate(-90deg) translate(0, 5px);
          transition: transform 0.2s ease-in-out;
        }
      }
    }
  }

  &:hover {
    .__slider-nav:not(.--disabled) {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
  }
}
</style>
