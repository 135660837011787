import {useRouter} from '#app';
import {ListingType} from '~/@types/listing';
import {TypesenseSearchParams} from '~/@types/common';
import {useListingStore} from '~/store/listing';

export interface ListingSearchRouteQuery {
  keyword: string;
  listingTypes: ListingType[];
  bedrooms: string; // Number as string or empty string if not selected
  bathrooms: string; // Number as string or empty string if not selected
  garageSpaces: string; // Number as string or empty string if not selected
  livingAreas: string; // Number as string or empty string if not selected
  landArea: string; // Number as string or empty string if not selected
  budget: [number, number]; // budget from and to as a million value (ex: 1.5, 10 = 1.5M to 10M)
  mapBounds: string; // String or empty string if not selected example: '(northEast.lat, northEast.lng, northWest.lat, northWest.lng, southWest.lat, southWest.lng, southEast.lat, southEast.lng)'
  page: number; // Number as string or empty string if not selected
  sortBy: string; // String or empty string if not selected example: 'price:asc'
  companySlug?: string; // Use to get this company only listings
}

export interface ListingSearchRouteQuerySetQuery {
  keyword?: string;
  listingTypes?: ListingType[];
  bedrooms?: string;
  bathrooms?: string;
  garageSpaces?: string;
  livingAreas?: string;
  landArea?: string;
  budget?: [number, number];
  mapBounds?: string;
  page?: number;
  sortBy?: string;
  companySlug?: string;
}

export default class ListingSearchQueryHandler {
  public static defaultQuery: ListingSearchRouteQuery = {
    keyword: '',
    listingTypes: [],
    bedrooms: '',
    bathrooms: '',
    garageSpaces: '',
    livingAreas: '',
    landArea: '',
    budget: [0, 10],
    mapBounds: '',
    page: 1,
    sortBy: 'priority:desc',
  };

  /**
   * Set listing search route query
   *
   * @param setQuery
   * @param routePath
   */
  public static set (setQuery: ListingSearchRouteQuerySetQuery, routePath?: string) {
    const router = useRouter();
    if (!setQuery.page) {
      setQuery.page = 1;
    }

    if (routePath) {
      router.push({path: routePath, query: {...this.get(), ...setQuery}});
    } else {
      router.push({query: {...this.get(), ...setQuery}});
    }
  }

  /**
   * Get listing search route query
   */
  public static get (): ListingSearchRouteQuery {
    const route = useRoute();

    const returnValue = {...this.defaultQuery, ...route.query};

    // Format string budget to array
    let budget = returnValue.budget as string | [number, number];
    if (typeof budget === 'string' && budget.length > 2) {
      budget = budget.split(',').map((value) => parseFloat(value)) as [number, number];
      if (budget.length === 2) {
        returnValue.budget = budget as [number, number];
      } else {
        returnValue.budget = [0, 10];
      }
    }

    // Format string listingTypes to array
    let listingTypes = returnValue.listingTypes as string | ListingType[];
    if (typeof listingTypes === 'string' && listingTypes.length > 2) {
      listingTypes = listingTypes.split(',') as ListingType[];
      returnValue.listingTypes = listingTypes as ListingType[];
    }
    return returnValue;
  }

  /**
   * Get listing search typesense query data
   */
  public static getTypesenseQuery (): TypesenseSearchParams {
    const params = this.get();
    let typesenseFilterBy = '';

    // Set common query params
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        if (['bedrooms', 'bathrooms', 'garageSpaces', 'livingAreas', 'landArea'].includes(key)) {
          // Add && if not first query
          typesenseFilterBy = typesenseFilterBy === '' ? '' : `${typesenseFilterBy} && `;
          typesenseFilterBy += ` ${key}:=${value}`;
        } else {
          switch (key) {
            case 'budget':
              if (Array.isArray(value) && value.length === 2) {
                if (value[0] === 0 && value[1] === 10) {
                  continue;
                }
                // Add && if not first query
                typesenseFilterBy = typesenseFilterBy === '' ? '' : `${typesenseFilterBy} && `;
                typesenseFilterBy += ` price:=[${(value[0] as number) * 1000000}..${(value[1] as number) * 1000000}]`;
              }
              break;
          }
        }
      }
    }

    // Set listing types and map bounds. Because design does not have map bounds, we need to check if it is a design or not
    let mapBoundsString = '';
    if (params.mapBounds) {
      mapBoundsString += `locations:${params.mapBounds} &&`;
    }
    if (params.listingTypes.length > 0) {
      // Add && if not first query
      typesenseFilterBy = typesenseFilterBy === '' ? '' : `${typesenseFilterBy} && `;
      typesenseFilterBy += `${mapBoundsString} listingTypes:=[${params.listingTypes.join(',')}]`;
    } else if (params.mapBounds) {
      // Add && if not first query
      typesenseFilterBy = typesenseFilterBy === '' ? '' : `${typesenseFilterBy} && `;
      typesenseFilterBy += `locations:${params.mapBounds}`;
    }

    return {
      keyword: params.keyword,
      filterBy: typesenseFilterBy,
      sortBy: params.sortBy,
      page: params.page,
    };
  }

  /**
   * Check if current params is different to fetched params
   */
  public static isCurrentParamsDifferentToFetchedParams () {
    const route = useRoute();
    const listingStore = useListingStore();
    const currentParams = JSON.stringify(route.query) + JSON.stringify(listingStore.companySlug);
    const fetchedParams = listingStore.lastFetchedParams;
    return currentParams !== fetchedParams;
  }
}
