import { defineStore } from 'pinia';
import {ScrollXY} from '~/@types/layout';

export interface LayoutState {
  scrollXY: ScrollXY;
  mobileMenuStatus: boolean;
  isChatOpen: boolean;
  isEmailEnquiryOpen: boolean;
}

export const useLayoutStore = defineStore('layout', {
  state: (): LayoutState => ({
    scrollXY: {x: 0, y: 0},
    mobileMenuStatus: false,
    isChatOpen: false,
    isEmailEnquiryOpen: false,
  }),
  getters: {
    scrollX: (state: LayoutState) => (state.scrollXY.x),
    scrollY: (state: LayoutState) => (state.scrollXY.y),
    isMobileMenuOpen: (state: LayoutState) => (state.mobileMenuStatus),
  },
  actions: {
    setScrollXY (scrollPositionData: ScrollXY) {
      this.scrollXY = scrollPositionData;
    },
    toggleMobileMenuStatus () {
      this.mobileMenuStatus = !this.mobileMenuStatus;
    },
    setChatOpen (isOpen: boolean) {
      this.isChatOpen = isOpen;
    },
    setEmailEnquiryOpen (isOpen: boolean) {
      this.isEmailEnquiryOpen = isOpen;
    },
  },
});
