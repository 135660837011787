<template>
  <nav class="main-nav">
    <div class="__inner-nav-container">
      <ul>
        <li :class="{'--active': route.path === '/listing/search'}">
          <nuxt-link to="/listing/search">
            Search
          </nuxt-link>
        </li>
        <li :class="{'--active': route.path === '/builder/search'}">
          <nuxt-link to="/builder/search">
            Builders
          </nuxt-link>
        </li>
        <li :class="{'--active': route.path === '/developments/search'}">
          <nuxt-link to="/developments/search">
            Developments
          </nuxt-link>
        </li>
        <li :class="{'--active': route.path === '/showhomes/search'}">
          <nuxt-link to="/showhomes/search">
            Showhomes
          </nuxt-link>
        </li>
        <li :class="{'--active': route.path === '/home-loans'}">
          <nuxt-link to="/home-loans">
            Home Loans
          </nuxt-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const route = useRoute();
</script>
