export default (includeTimestamp = true) => {
  let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });

  if (!includeTimestamp) {
    return guid;
  }

  const timestamp = Date.now();
  guid = guid + '-' + timestamp;

  return guid;
};
