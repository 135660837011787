<template>
  <div id="__clou-web-wrapper" class="clou-web-wrapper">
    <NuxtLayout name="default">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import {useLayoutStore} from '~/store/layout';
import {useRoute} from '#app';

const layoutStore = useLayoutStore();
const route = useRoute();
const {$sendTrackingData, $setUserId} = useNuxtApp();
const config = useRuntimeConfig();

/**
 * @_HEAD Hook
 */
useHead({
  htmlAttrs: {
    lang: 'en',
  },
  link: [
    {
      rel: 'preconnect',
      href: '/_vercel/image',
    },
    {
      rel: 'preconnect',
      href: 'https://store.newhomeshub.co.nz',
    },
    {
      rel: 'preconnect',
      href: 'https://search.newhomeshub.co.nz',
    },
  ],
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
    },
    {
      name: 'robots',
      content: config.public.appEnv === 'production' ? 'index, follow' : 'noindex, nofollow',
    },
  ],
});

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  handleErrors();
  document.addEventListener('scroll', pageScrolled);

  // Set user id
  $setUserId();
  fireTrackingEvent();
  console.log('mounted');
});

/**
 * @_WATCHER - route
 */
watch(() => route, () => {
  $sendTrackingData();
}, {deep: true});

/**
 * Fire tracking event every 10 seconds
 */
function fireTrackingEvent() {
  $sendTrackingData();

  setTimeout(() => {
    fireTrackingEvent();
  }, 10000);
}

/**
 * Page scrolled
 */
function pageScrolled() {
  layoutStore.setScrollXY({x: window.top?.scrollX || 0, y: window.top?.scrollY || 0});
}

function handleErrors () {
  const ignoredErrors: string[] = [];
  const messages = [
    'Uncaught NotFoundError: Failed to execute \'insertBefore\' on \'Node\': The node before which the new node is to be inserted is not a child of this node.', // chromium based
    'NotFoundError: The object can not be found here.', // safari
  ];
  if (typeof window !== 'undefined') {
    window.addEventListener('error', (ev) => {
      if (messages.includes(ev.message)) {
        ev.preventDefault();
        window.location.reload();
      } else if (ignoredErrors.includes(ev.message)) {
        // ignore error
      }
    });
  }
}
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
