<template>
  <div class="el-buttons-button-group" :class="shouldDisplayMobileButtonGroup ? '--mobile' : '--desktop'">
    <div v-if="!shouldDisplayMobileButtonGroup" ref="elButtonGroup" class="__button-group-full-view" :class="{'--hidden': shouldDisplayMobileButtonGroup}">
      <ElementsButtonsRegular v-for="(buttonItem, index) in buttonItems" :key="`button-item-${index}`" :class="{'--selected': selectedItem === buttonItem.value}" :label="buttonItem.label" :append-icon="getIcon(buttonItem)" @click="updateModelValue(buttonItem.value)" />
    </div>
    <div v-else class="__mobile-view" :class="{'--hidden': !shouldDisplayMobileButtonGroup}">
      <BlocksSlider v-model="selectedItemNumber" @update:model-value="setSelectedItem()">
        <div v-for="(buttonItem, index) in buttonItems" :key="`button-group-item-${index}`" class="mobile-button-item">
          {{ buttonItem.label }}
        </div>
      </BlocksSlider>
    </div>
  </div>
</template>

<script setup lang="ts">
import {PropType, Ref} from 'vue';
import {ButtonGroupItem} from '~/@types/common';

// EMIT Definitions
//----------------------------------------------------------------------------------------------------------------------
const emit = defineEmits<{
  (event: 'update:modelValue', payload: string | number | null): void;
  (event: 'change'): void;
}>();

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  modelValue: {
    type: [Number, String, Object] as PropType<any>,
    default: null,
  },
  buttonItems: {
    type: Array as PropType<ButtonGroupItem[]>,
  },
  showMobileButtonGroup: {
    type: Boolean,
    default: false,
  },
  minDesktopWidth: { // When the screen goes below this level it triggers the mobile buttons / vice versa
    type: Number,
    default: 0,
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const selectedItem: Ref<string | number | object | null> = ref(null);
const isMobileButtonGroup: Ref<boolean> = ref(false);
const elButtonGroup: Ref<HTMLElement | null> = ref(null);
const selectedItemNumber: Ref<number> = ref(0);
const shouldDisplayMobileButtonGroup: Ref<boolean> = ref(false);

/**
 * Component MOUNTED!
 */
onMounted(() => {
  initModelValue();
  setTimeout(() => {
    toggleMobileButtonGroup();
  }, 500);

  window.addEventListener('resize', toggleMobileButtonGroup);
});

/**
 * When modelValuesChanged
 */
watch(() => props.modelValue, () => {
  if (selectedItem.value !== props.modelValue) {
    selectedItem.value = props.modelValue;
  }
});

/**
 * Initiate the model value
 */
function initModelValue () {
  selectedItem.value = props.modelValue;
}

/**
 * Get the button icon
 *
 * @param buttonItem
 */
function getIcon (buttonItem: ButtonGroupItem) {
  if (buttonItem.icon) {
    if (selectedItem.value === buttonItem.value && buttonItem.selectedIcon) {
      return buttonItem.selectedIcon;
    }
    return buttonItem.icon;
  }
  return null;
}

/**
 * Set selected item
 */
function setSelectedItem () {
  const index = (selectedItemNumber.value - 1);
  if (props.buttonItems && props.buttonItems[index]) {
    updateModelValue(props.buttonItems[index].value);
  }
}

/**
 * Update model value
 */
function updateModelValue (value: any) {
  selectedItem.value = value;
  emit('update:modelValue', value);
  emit('change');
}

/**
 * Check is mobile button group or not
 */
function toggleMobileButtonGroup () {
  if (elButtonGroup && elButtonGroup.value && props.showMobileButtonGroup !== false) {
    let childrenWidth = 0;
    elButtonGroup.value.childNodes.forEach((child) => {
      if (child && (child as any).clientWidth) {
        childrenWidth+= (child as any).clientWidth;
      }
    });

    const parentWidth = elButtonGroup.value.clientWidth;

    isMobileButtonGroup.value = childrenWidth === 0 || parentWidth < childrenWidth;
  }

  setShouldDisplayMobileButtonGroup();
}

function setShouldDisplayMobileButtonGroup () {
  if (props.minDesktopWidth && window) {
    shouldDisplayMobileButtonGroup.value = window.innerWidth < props.minDesktopWidth;
  } else {
    shouldDisplayMobileButtonGroup.value = isMobileButtonGroup.value;
  }
}
</script>

<style lang="scss">
.el-buttons-button-group {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .__button-group-full-view {
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: flex-end;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 12px;

    .el-button-regular {
      background: transparent;
      border: 1px solid rgb(var(--primary));
      width: fit-content;
      height: 24px;
      border-radius: 18px;
      padding: 2px 12px;

      &:not(:last-child) {
        margin-right: 12px;
      }

      .__button-label {
        color: rgb(var(--text-lighter-1));
        text-transform: capitalize;
        font-weight: 500;
        font-size: 12px;
      }

      &:hover, &.--selected {
        background: rgb(var(--primary));

        .__button-label {
          color: rgb(var(--text-light));
        }
      }

      transition: all 0.2s ease-in-out;
    }
  }

  &.--mobile {
    justify-content: center;
    .__mobile-view {
      min-width: 100%;
      height: 40px;
      padding: 0 24px;
      margin-top: 5px;

      .blocks-slider {
        .__slider-items {
          margin-top: -2px !important;
          height: 38px !important;
        }
      }

      .mobile-button-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-weight: 700;
        color: rgb(var(--primary));
        font-size: 18px;
      }
    }
  }

  &.--desktop {
    justify-content: flex-end !important;
  }

  .__button-group-full-view,
  .__mobile-view {
    &.--hidden {
      visibility: hidden;
      display: none;
    }
  }

  @media screen and (max-width: $grid-sm) {
    .__button-group-full-view {
      .el-button-regular {
        padding: 2px 12px !important;

        &:not(:last-child) {
          margin-right: 4px;
        }

        .__button-inner {
          min-width: unset !important;
        }
      }
    }
  }
}
</style>
