import { default as indexutcnovDtlEMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as indexNwoJG7pLnGMeta } from "/vercel/path0/pages/auth/forgot-password/index.vue?macro=true";
import { default as indexSrqw7Nv0JTMeta } from "/vercel/path0/pages/auth/login/index.vue?macro=true";
import { default as indexqoC0NV4gDEMeta } from "/vercel/path0/pages/auth/my-account/index.vue?macro=true";
import { default as indexBY1w7pioW4Meta } from "/vercel/path0/pages/auth/register/index.vue?macro=true";
import { default as _91resetToken_937rckzHgno4Meta } from "/vercel/path0/pages/auth/reset-password/[resetToken].vue?macro=true";
import { default as _91guid_93rsmsSsGm1pMeta } from "/vercel/path0/pages/auth/verify-email/[guid].vue?macro=true";
import { default as indexW6FSMpFq42Meta } from "/vercel/path0/pages/builder/[slug]/listings/index.vue?macro=true";
import { default as indexwCl2QCWji5Meta } from "/vercel/path0/pages/builder/[slug]/view/index.vue?macro=true";
import { default as indexfpfG5i093kMeta } from "/vercel/path0/pages/builder/search/index.vue?macro=true";
import { default as indexDBbnZWdxZ3Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as viewbAsj5cPI4mMeta } from "/vercel/path0/pages/design/[slug]/view.vue?macro=true";
import { default as viewEDO1BNjzKUMeta } from "/vercel/path0/pages/development/[slug]/view.vue?macro=true";
import { default as viewIqQASrfFNdMeta } from "/vercel/path0/pages/developments/[slug]/view.vue?macro=true";
import { default as indexicXcFFijBTMeta } from "/vercel/path0/pages/developments/search/index.vue?macro=true";
import { default as indexaDAhiytYUoMeta } from "/vercel/path0/pages/home-loans/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as viewbW8ngdJjoWMeta } from "/vercel/path0/pages/listing/[slug]/view.vue?macro=true";
import { default as indexoEbsMO5E0NMeta } from "/vercel/path0/pages/listing/search/index.vue?macro=true";
import { default as indexQ6YvFoaCBnMeta } from "/vercel/path0/pages/nh-hub-terms-of-use/index.vue?macro=true";
import { default as indexZqjRDHar7KMeta } from "/vercel/path0/pages/privacy-policy/index.vue?macro=true";
import { default as indexOzTmQwD6UMMeta } from "/vercel/path0/pages/showhomes/search/index.vue?macro=true";
import { default as indexPhPz3MuV0pMeta } from "/vercel/path0/pages/terms-of-use/index.vue?macro=true";
export default [
  {
    name: indexutcnovDtlEMeta?.name ?? "about",
    path: indexutcnovDtlEMeta?.path ?? "/about",
    meta: indexutcnovDtlEMeta || {},
    alias: indexutcnovDtlEMeta?.alias || [],
    redirect: indexutcnovDtlEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexNwoJG7pLnGMeta?.name ?? "auth-forgot-password",
    path: indexNwoJG7pLnGMeta?.path ?? "/auth/forgot-password",
    meta: indexNwoJG7pLnGMeta || {},
    alias: indexNwoJG7pLnGMeta?.alias || [],
    redirect: indexNwoJG7pLnGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexSrqw7Nv0JTMeta?.name ?? "auth-login",
    path: indexSrqw7Nv0JTMeta?.path ?? "/auth/login",
    meta: indexSrqw7Nv0JTMeta || {},
    alias: indexSrqw7Nv0JTMeta?.alias || [],
    redirect: indexSrqw7Nv0JTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexqoC0NV4gDEMeta?.name ?? "auth-my-account",
    path: indexqoC0NV4gDEMeta?.path ?? "/auth/my-account",
    meta: indexqoC0NV4gDEMeta || {},
    alias: indexqoC0NV4gDEMeta?.alias || [],
    redirect: indexqoC0NV4gDEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexBY1w7pioW4Meta?.name ?? "auth-register",
    path: indexBY1w7pioW4Meta?.path ?? "/auth/register",
    meta: indexBY1w7pioW4Meta || {},
    alias: indexBY1w7pioW4Meta?.alias || [],
    redirect: indexBY1w7pioW4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/register/index.vue").then(m => m.default || m)
  },
  {
    name: _91resetToken_937rckzHgno4Meta?.name ?? "auth-reset-password-resetToken",
    path: _91resetToken_937rckzHgno4Meta?.path ?? "/auth/reset-password/:resetToken()",
    meta: _91resetToken_937rckzHgno4Meta || {},
    alias: _91resetToken_937rckzHgno4Meta?.alias || [],
    redirect: _91resetToken_937rckzHgno4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/reset-password/[resetToken].vue").then(m => m.default || m)
  },
  {
    name: _91guid_93rsmsSsGm1pMeta?.name ?? "auth-verify-email-guid",
    path: _91guid_93rsmsSsGm1pMeta?.path ?? "/auth/verify-email/:guid()",
    meta: _91guid_93rsmsSsGm1pMeta || {},
    alias: _91guid_93rsmsSsGm1pMeta?.alias || [],
    redirect: _91guid_93rsmsSsGm1pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/verify-email/[guid].vue").then(m => m.default || m)
  },
  {
    name: indexW6FSMpFq42Meta?.name ?? "builder-slug-listings",
    path: indexW6FSMpFq42Meta?.path ?? "/builder/:slug()/listings",
    meta: indexW6FSMpFq42Meta || {},
    alias: indexW6FSMpFq42Meta?.alias || [],
    redirect: indexW6FSMpFq42Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/builder/[slug]/listings/index.vue").then(m => m.default || m)
  },
  {
    name: indexwCl2QCWji5Meta?.name ?? "builder-slug-view",
    path: indexwCl2QCWji5Meta?.path ?? "/builder/:slug()/view",
    meta: indexwCl2QCWji5Meta || {},
    alias: indexwCl2QCWji5Meta?.alias || [],
    redirect: indexwCl2QCWji5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/builder/[slug]/view/index.vue").then(m => m.default || m)
  },
  {
    name: indexfpfG5i093kMeta?.name ?? "builder-search",
    path: indexfpfG5i093kMeta?.path ?? "/builder/search",
    meta: indexfpfG5i093kMeta || {},
    alias: indexfpfG5i093kMeta?.alias || [],
    redirect: indexfpfG5i093kMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/builder/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexDBbnZWdxZ3Meta?.name ?? "contact",
    path: indexDBbnZWdxZ3Meta?.path ?? "/contact",
    meta: indexDBbnZWdxZ3Meta || {},
    alias: indexDBbnZWdxZ3Meta?.alias || [],
    redirect: indexDBbnZWdxZ3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: viewbAsj5cPI4mMeta?.name ?? "design-slug-view",
    path: viewbAsj5cPI4mMeta?.path ?? "/design/:slug()/view",
    meta: viewbAsj5cPI4mMeta || {},
    alias: viewbAsj5cPI4mMeta?.alias || [],
    redirect: viewbAsj5cPI4mMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/design/[slug]/view.vue").then(m => m.default || m)
  },
  {
    name: viewEDO1BNjzKUMeta?.name ?? "development-slug-view",
    path: viewEDO1BNjzKUMeta?.path ?? "/development/:slug()/view",
    meta: viewEDO1BNjzKUMeta || {},
    alias: viewEDO1BNjzKUMeta?.alias || [],
    redirect: viewEDO1BNjzKUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/development/[slug]/view.vue").then(m => m.default || m)
  },
  {
    name: viewIqQASrfFNdMeta?.name ?? "developments-slug-view",
    path: viewIqQASrfFNdMeta?.path ?? "/developments/:slug()/view",
    meta: viewIqQASrfFNdMeta || {},
    alias: viewIqQASrfFNdMeta?.alias || [],
    redirect: viewIqQASrfFNdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/developments/[slug]/view.vue").then(m => m.default || m)
  },
  {
    name: indexicXcFFijBTMeta?.name ?? "developments-search",
    path: indexicXcFFijBTMeta?.path ?? "/developments/search",
    meta: indexicXcFFijBTMeta || {},
    alias: indexicXcFFijBTMeta?.alias || [],
    redirect: indexicXcFFijBTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/developments/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexaDAhiytYUoMeta?.name ?? "home-loans",
    path: indexaDAhiytYUoMeta?.path ?? "/home-loans",
    meta: indexaDAhiytYUoMeta || {},
    alias: indexaDAhiytYUoMeta?.alias || [],
    redirect: indexaDAhiytYUoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/home-loans/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: viewbW8ngdJjoWMeta?.name ?? "listing-slug-view",
    path: viewbW8ngdJjoWMeta?.path ?? "/listing/:slug()/view",
    meta: viewbW8ngdJjoWMeta || {},
    alias: viewbW8ngdJjoWMeta?.alias || [],
    redirect: viewbW8ngdJjoWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/listing/[slug]/view.vue").then(m => m.default || m)
  },
  {
    name: indexoEbsMO5E0NMeta?.name ?? "listing-search",
    path: indexoEbsMO5E0NMeta?.path ?? "/listing/search",
    meta: indexoEbsMO5E0NMeta || {},
    alias: indexoEbsMO5E0NMeta?.alias || [],
    redirect: indexoEbsMO5E0NMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/listing/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ6YvFoaCBnMeta?.name ?? "nh-hub-terms-of-use",
    path: indexQ6YvFoaCBnMeta?.path ?? "/nh-hub-terms-of-use",
    meta: indexQ6YvFoaCBnMeta || {},
    alias: indexQ6YvFoaCBnMeta?.alias || [],
    redirect: indexQ6YvFoaCBnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/nh-hub-terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexZqjRDHar7KMeta?.name ?? "privacy-policy",
    path: indexZqjRDHar7KMeta?.path ?? "/privacy-policy",
    meta: indexZqjRDHar7KMeta || {},
    alias: indexZqjRDHar7KMeta?.alias || [],
    redirect: indexZqjRDHar7KMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexOzTmQwD6UMMeta?.name ?? "showhomes-search",
    path: indexOzTmQwD6UMMeta?.path ?? "/showhomes/search",
    meta: indexOzTmQwD6UMMeta || {},
    alias: indexOzTmQwD6UMMeta?.alias || [],
    redirect: indexOzTmQwD6UMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/showhomes/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexPhPz3MuV0pMeta?.name ?? "terms-of-use",
    path: indexPhPz3MuV0pMeta?.path ?? "/terms-of-use",
    meta: indexPhPz3MuV0pMeta || {},
    alias: indexPhPz3MuV0pMeta?.alias || [],
    redirect: indexPhPz3MuV0pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-of-use/index.vue").then(m => m.default || m)
  }
]