import { defineStore } from 'pinia';
import globalApiHeaders from '~/composables/globalApiHeaders';
import {UserFavoriteItem} from '~/@types/user';

export interface UserState {
  token: null | string;
  expireAt: null | string;
  user: any | null;
  userId: string | null;
  favorites: UserFavoriteItem[];
  chatInitiationData: {location: string; locationDid: string; url: string; roomId: string | null, hasError?: boolean} | null;
}

export const useAuthUserStore = defineStore('authUser', {
  state: (): UserState => ({
    token: null,
    expireAt: null,
    user: null,
    userId: null,
    favorites: [],
    chatInitiationData: null,
  }),
  persist: {
    storage: persistedState.cookiesWithOptions({
      maxAge: 3600 * 1000 * 24 * 365,
    }),
  },
  actions: {
    setAuthUser (data: {token: string | null; expires_at: string} | null) {
      if (data) {
        this.token = data.token;
        this.expireAt = data.expires_at;
      } else {
        this.token = null;
        this.expireAt = null;
        setTimeout(() => {
          this.user = null;
        }, 100);
      }
    },
    setUser (user: any) {
      this.user = user;
    },
    async getUser () {
      try {
        const config = useRuntimeConfig();
        const {data} = await useFetch('/public/user/', {
          baseURL: config.public.apiUrl,
            method: 'GET',
            headers: globalApiHeaders(),
        });
        const response = JSON.parse(JSON.stringify(data.value as any));

        if (response && response.code === 200) {
          this.setUser(response.data);
        } else {
          this.setUser(null);
        }
        return;
      } catch (error) {
        // TODO: handle error
        return;
      }
    },
    setUserId (userId: string | null) {
      this.userId = userId;
    },
    setFavorites (favorites: UserFavoriteItem[]) {
      this.favorites = favorites;
    },
    setChatInitiationData (data: {location: string; locationDid: string; url: string; roomId: string | null; hasError?: boolean; agentDid?: string | null} | null) {
      this.chatInitiationData = data;
    },
  },
});
