export const propertyTypes: {label: string; value: string, color?: string}[] = [
  {label: 'House & Land', value: 'House & Land', color: '#0038bd'},
  {label: 'Land', value: 'Land', color: '#00b92a'},
  {label: 'Showhomes', value: 'Showhome', color: '#ff4146'},
  {label: 'Designs', value: 'Design', color: '#6b51ee'},
  {label: 'Ready to Move in', value: 'Ready to Move in', color: '#448FCB'},
  {label: 'Turn Key', value: 'Turn Key', color: '#a52904'},
  {label: 'Affordable Housing', value: 'Affordable Housing', color: '#350369'},
  {label: 'Investment', value: 'Investment', color: '#008f8e'},
  {label: 'Terraced Housing', value: 'Terraced Housing', color: '#ff4e00'},
  {label: 'Apartments', value: 'Apartment', color: '#5b6e71'},
  {label: 'Tiny Homes', value: 'Tiny Home', color: '#F3B934'},
  {label: 'Transportable Homes', value: 'Transportable Home', color: '#82cdd7'},
];
