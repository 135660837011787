import VueGtag, {useGtag} from 'vue-gtag-next';
import {TrackingData} from '~/@types/analytics';
import {useRoute} from '#app';
import {userInsightStore} from '~/store/insight';
import globalApiHeaders from '~/composables/globalApiHeaders';
import {useAuthUserStore} from '~/store/authUser';
import {MetaPixelEventParams} from '~/@types/meta';

export default defineNuxtPlugin((nuxtApp) => {
  // Initialize Google Analytics
  const config = useRuntimeConfig();
  if (config.public.googleAnalyticsId) {
    nuxtApp.vueApp.use(VueGtag, {
      property: {
        id: config.public.googleAnalyticsId,
      },
    });
  }

  // DATA Definitions
  //--------------------------------------------------------------------------------------------------------------------
  const {event} = useGtag();
  const authUserStore = useAuthUserStore();
  const insightStore = userInsightStore();
  const route = useRoute();

  /**
   * Trigger analytics tracking
   *
   * @param trackingData
   */
  const analyticsTrack = (trackingData: TrackingData) => {
    // Track analytics event
    event(trackingData.action, {
      event_category: trackingData.property,
      user_id: authUserStore.userId,
      slug: route.path,
      ...trackingData,
    });

    // Add user id and slug to tracking data
    const trackingDataObject = trackingData;  // create a copy of obj
    if (trackingDataObject.eventData) {
      trackingDataObject.eventData.userId = authUserStore.userId;
      trackingDataObject.eventData.slug = route.path;
    } else {
      trackingDataObject.eventData = {
        userId: authUserStore.userId,
        slug: route.path,
      };
    }

    // Push to analytics store
    insightStore.pushTrackingData(trackingDataObject);
  };

  /**
   * Send tracking data to the server
   */
  const sendTrackingData = async () => {
    const trackingData = insightStore.trackingData;
    if (trackingData.length) {
      insightStore.flushTrackingData();
      const baseUrl = nuxtApp.$config.public.apiUrl;
      await fetch(baseUrl + '/public/analytics/track', {
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify({insightData: trackingData}),
      });
    }
  };

  return {
    provide: {
      analyticsTrack,
      sendTrackingData,
    },
  };
});
