import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eskviOYyLt from "/vercel/path0/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import templates_pwa_client_15bedddc_j9xTpfLwQp from "/vercel/path0/.nuxt/templates.pwa.client.15bedddc.ts";
import plugin_hwlJ5PmHMh from "/vercel/path0/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import disable_console_client_t3ejL3y35X from "/vercel/path0/plugins/disable-console.client.ts";
import facebook_pixel_client_DZvV2r4W6e from "/vercel/path0/plugins/facebook-pixel.client.ts";
import googleMap_client_eF4PUJskUQ from "/vercel/path0/plugins/googleMap.client.ts";
import loginWithFacebook_client_0faO2rEnNC from "/vercel/path0/plugins/loginWithFacebook.client.ts";
import refreshToken_client_ggtSkOesmq from "/vercel/path0/plugins/refreshToken.client.ts";
import search_MIZqzcnD8X from "/vercel/path0/plugins/search.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import userAuth_76A7PLLXxN from "/vercel/path0/plugins/userAuth.ts";
import vueAdvancedChat_client_vZDhRNEqED from "/vercel/path0/plugins/vueAdvancedChat.client.ts";
import ws_client_OjP9AeNMD2 from "/vercel/path0/plugins/ws.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eskviOYyLt,
  templates_pwa_client_15bedddc_j9xTpfLwQp,
  plugin_hwlJ5PmHMh,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  analytics_client_mtqVw3YRXk,
  disable_console_client_t3ejL3y35X,
  facebook_pixel_client_DZvV2r4W6e,
  googleMap_client_eF4PUJskUQ,
  loginWithFacebook_client_0faO2rEnNC,
  refreshToken_client_ggtSkOesmq,
  search_MIZqzcnD8X,
  sentry_3AyO8nEfhE,
  userAuth_76A7PLLXxN,
  vueAdvancedChat_client_vZDhRNEqED,
  ws_client_OjP9AeNMD2
]