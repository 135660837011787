import { defineStore } from 'pinia';
import {TrackingData} from '~/@types/analytics';

export interface AuthState {
  trackingData: TrackingData[];
}

export const userInsightStore = defineStore('insights', {
  state: (): AuthState => ({
    trackingData: [],
  }),
  getters: {
    //
  },
  actions: {
    pushTrackingData (trackingData: TrackingData) {
      this.trackingData.push(trackingData);
    },
    flushTrackingData () {
      this.trackingData = [];
    },
  },
});
