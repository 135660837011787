import { defineStore } from 'pinia';
import {CompanyData} from '~/@types/company';

export interface FilterBy {
  field: string;
  value: string | null;
}

export interface CompanyState {
  companytData: CompanyData;
  queryOptions: {
    searchKeyword: string | null
    filterBy: FilterBy[];
    page: number;
  };
  requestId: string | null;
  isLoading: boolean;
  sortBy: string | null;
  urlParamObject: object | null,
}

export const useCompanyStore = defineStore('company', {
  state: (): CompanyState => ({
    companytData: {
      found: 0,
      facet_counts: [],
      hits: [],
      out_of: 0,
      page: 1,
      request_params: {
        per_page: 96,
      },
    },
    queryOptions: {
      searchKeyword: null,
      filterBy: [],
      page: 1,
    },
    requestId: null,
    sortBy: null,
    urlParamObject: null,
    isLoading: false,
  }),
  getters: {
    //
  },
  actions: {
    setCompanyData (companytData: any) {
      this.companytData = companytData;
    },
    setQueryOptions (queryOptions: {searchKeyword?: string | null; filterBy?: FilterBy[]}) {
      if (JSON.stringify(queryOptions) !== JSON.stringify(this.queryOptions)) {
        const {$company} = useNuxtApp();

        // Set search keyword
        if (typeof queryOptions.searchKeyword !== 'undefined') {
          this.queryOptions.searchKeyword = queryOptions.searchKeyword;
        }

        // Set filter by
        if (queryOptions.filterBy) {
          this.queryOptions.filterBy = queryOptions.filterBy;
        }

        this.queryOptions.page = 1;
        ($company as any).search();
      }
    },
    setRequestId (requestId: string | null) {
      this.requestId = requestId;
    },
    setPage (page: number) {
      const {$company} = useNuxtApp();
      this.queryOptions.page = page;
      ($company as any).search();
    },
    setUrlParamObject (urlParamObject: object | null) {
      this.urlParamObject = urlParamObject;
    },
    setIsLoading (isLoading: boolean) {
      this.isLoading = isLoading;
    },
  },
});
