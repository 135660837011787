import { defineStore } from 'pinia';
import {ListingWidget} from '~/@types/listing';
import {DevelopmentWidget} from '~/@types/development';
import {CompanyWidget} from '~/@types/company';

export interface FeaturedState {
  houseAndLand: ListingWidget[];
  lands: ListingWidget[];
  showhomes: ListingWidget[];
  designs: ListingWidget[];
  developments: DevelopmentWidget[];
  builders: CompanyWidget[];
  isLoading: boolean;
}

export const useFeaturedStore = defineStore('featured', {
  state: (): FeaturedState => ({
    houseAndLand: [],
    lands: [],
    showhomes: [],
    designs: [],
    developments: [],
    builders: [],
    isLoading: false,
  }),
  persist: {
    paths: ['featured'],
    storage: persistedState.cookiesWithOptions({
      maxAge: 300,
    }),
  },
  getters: {
    //
  },
  actions: {
    setFeaturedItems (category: 'houseAndLand' | 'lands' | 'showhomes' | 'designs' | 'developments' | 'builders', featuredItems: any[]) {
      if (this[category].length < 1) {
        this[category] = featuredItems;
      }
    },
    setIsLoading (isLoading: boolean) {
      this.isLoading = isLoading;
    },
  },
});
