<template>
  <div class="el-icon-button no-highlight" :class="{'--active': isActive, '--not-clickable': notClickable !== false}" @click="triggerClick">
    <div class="__el-icon-button-inner" :class="{'__is-link': iconLink}">
      <nuxt-link v-if="iconLink" :to="iconLink" :target="linkTarget">
        <img v-lazy-load  :data-src="iconSrc" :alt="`${iconAlt} link`" width="18" height="18">
      </nuxt-link>
      <img v-lazy-load  v-else :data-src="iconSrc" :alt="iconAlt" width="18" height="18">
    </div>
    <span class="__icon-button-label">{{ label }}</span>
  </div>
</template>

<script setup lang="ts">
// PROPS
const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  notClickable: {
    type: Boolean,
    default: false,
  },
  iconAlt: {
    type: String,
    default: 'icon',
  },
  iconSrc: {
    type: String,
    required: true,
  },
  iconLink: {
    type: String,
    default: null,
  },
  linkTarget: {
    type: String,
    default: '_self',
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

// EMIT Definitions
const emit = defineEmits(['click']);

/**
 * Trigger click event
 */
function triggerClick() {
  emit('click');
  return true;
}
</script>

<style lang="scss">
.el-icon-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  .__el-icon-button-inner {
    scale: 1;
    opacity: 0.8;
    transition: all 0.2s ease-in-out;

    img {
      max-width: 20px;
      height: auto;
    }
  }

  &:hover, &.--active {
    background-color: rgb(var(--background));

    .__el-icon-button-inner {
      opacity: 1;
      scale: 1.2;
    }
    transition: all 0.2s ease-in-out;
  }

  .__icon-button-label {
    color: rgb(var(--text-lighter-1));
    font-size: 14px;
    margin-left: 5px;
  }

  &.--not-clickable {
    cursor: default;

    &.--active, &:hover {
      background-color: transparent !important;
    }

    .__el-icon-button-inner {
      opacity: 1;
      background-color: rgb(var(--background));
      padding: 8px;
      border-radius: 50%;
      display: flex;

      img {
        width: 18px;
        height: auto;
      }

      &:hover {
        scale: 1;
      }
    }
  }
}
</style>
