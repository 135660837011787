import {MetaPixelEventName, MetaPixelEventParams} from '~/@types/meta';

export default defineNuxtPlugin(({$config}) => {
  if ((window as any).fbq) return;

  (window as any).fbq = function() {
    (window as any).fbq.callMethod
      ? (window as any).fbq.callMethod.apply((window as any).fbq, arguments)
      : (window as any).fbq.queue.push(arguments);
  };

  if (!(window as any)._fbq) (window as any)._fbq = (window as any).fbq;

  (window as any).fbq.push = (window as any).fbq;
  (window as any).fbq.loaded = true;
  (window as any).fbq.version = '2.0';
  (window as any).fbq.queue = [];

  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://connect.facebook.net/en_US/fbevents.js';
  (document as any).getElementsByTagName('script')[0].parentNode.insertBefore(script, document.getElementsByTagName('script')[0]);

  (window as any).fbq('init', '1401986623228325');
  (window as any).fbq('track', 'PageView');

  // Define a trackEvent method
  const trackEvent = (eventName: MetaPixelEventName, eventParams: MetaPixelEventParams = {}) => {
    if (typeof (window as any).fbq !== 'function') {
      console.warn('Facebook Pixel not initialized yet.');
      return;
    }
    (window as any).fbq('track', eventName, eventParams);
  };

  // Return a provide object
  return {
    provide: {
      metaPixelTrackEvent: trackEvent,
    },
  };
});
