import {useAuthUserStore} from '~/store/authUser';
import globalApiHeaders from '~/composables/globalApiHeaders';
import {UserRegisterPayload, UserLoginPayload, UserUpdatePayload} from '~/@types/user';
import guid from '~/composables/guid';
import {ApiReturn} from '~/@types/common';

/**
 * Validate the user authentication
 */
const validateAuth = () => {
  const user = useCookie('authUser');
  const route = useRoute();
  const authUserStore = useAuthUserStore();

  if (user && user.value && (user.value as any).expireAt) {
    const expireAt = new Date((user.value as any).expireAt);
    const futureTime = new Date();
    futureTime.setMinutes(futureTime.getMinutes() + 5);
    // if (expireAt > futureTime) {
    //   if (['guest', 'common'].includes(String(route.meta.layout))) {
    //     window.location.reload();
    //   }
    //   return;
    // }
  }

  // authUserStore.setAuthUser(null);
  // user.value = null;


  // if (!['guest', 'common'].includes(String(route.meta.layout))) {
  //   window.location.reload();
  // }
};

/**
 * Export default plugin
 */
export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    runValidateAuth(true);
  }

  const config = useRuntimeConfig();

  /**
   * Register User
   *
   * @param payload
   */
  const register = async (payload: UserRegisterPayload): Promise<{error: any, data: any; code: 200 | 201 | 406 | 407 | 500}> => {
    try {
      const {data} = await useFetch('/public/auth/register', {
        baseURL: config.public.apiUrl,
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify(({
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          token: payload.token,
          password: payload.password,
          socialLoginData: payload.socialLoginData || null,
          avatarUrl: payload.avatarUrl || null,
        } as UserRegisterPayload)),
      });

      return (data.value as any);
    } catch (error) {
      // TODO handle error
      return {data: null, code: 500, error: null};
    }
  };

  /**
   * Login User
   *
   * @param payload
   */
  const login = async (payload: UserLoginPayload): Promise<{error: any, data: any; code: 200 | 201 | 406 | 407 | 500}> => {
    try {
      const {data} = await useFetch('/public/auth/login', {
        baseURL: config.public.apiUrl,
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify(({
          email: payload.email,
          password: payload.password,
          socialLoginData: payload.socialLoginData || null,
          token: payload.token,
        } as UserRegisterPayload)),
      });

      const authUserStore = useAuthUserStore();

      const response = JSON.parse(JSON.stringify(data.value as any));
      if (response && response.code === 200 && response.data.token) {
        const router = useRouter();

        // Set user token
        authUserStore.setAuthUser(response.data);

        await authUserStore.getUser();

        setTimeout(() => {
          // Update analytics user from guest user id to the auth user did
          setUserId();
          router.push('/auth/my-account');
          setTimeout(() => {
            window.location.reload();
          }, 50);
        }, 200);

        return {data: 'Login is successful', code: 200, error: null};
      } else {
        // Set user token
        authUserStore.setAuthUser(null);
      }

      return response;
    } catch (error) {
      // TODO handle error
      return {data: null, code: 500, error: null};
    }
  };

  /**
   * Logout User
   */
  const logout = async (): Promise<void> => {
    try {
      await useFetch('/public/user/logout', {
        baseURL: config.public.apiUrl,
        method: 'GET',
        headers: globalApiHeaders(),
      });
      const authUserStore = useAuthUserStore();
      const user = useCookie('user');
      user.value = null;
      authUserStore.setAuthUser(null);
      const router = useRouter();

      router.push('/auth/login');

      // Logout from facebook if the auth is from facebook
      (window as any).FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          (window as any).FB.logout();
        }
      });

      // Update analytics user from the auth user did to guest user id
      setTimeout(() => {
        setUserId();
      }, 500);
    } catch (error) {
      // TODO handle error
      return;
    }
  };

  /**
   * Set auth user or guest user id for analytics purpose
   */
  const setUserId = () => {
    const authUserStore = useAuthUserStore();
    if (authUserStore.user) {
      authUserStore.setUserId(authUserStore.user.did);
    } else {
      let userId = localStorage.getItem('user_id');
      if (userId) {
        authUserStore.setUserId(userId);
      } else {
        userId = `guest_${guid()}`;
        authUserStore.setUserId(userId);
        localStorage.setItem('user_id', userId);
      }
    }
  };

  /**
   * Verify Email
   */
  const verifyEmail = async (guid: string): Promise<number> => {
    try {
      const {data} = await useFetch('/public/auth/activate', {
        baseURL: config.public.apiUrl,
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify({
          guid,
        }),
      });

      return (data.value as any)?.code || 500;

    } catch (error) {
      // TODO handle error
      return 500;
    }
  };

  /**
   * Request reset password link
   */
  const requestResetPasswordLink = async (email: string, recaptchaToken: string): Promise<number> => {
    try {
      const {data} = await useFetch('/public/auth/request-reset-password-link', {
        baseURL: config.public.apiUrl,
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify({
          email,
          token: recaptchaToken,
        }),
      });

      return (data.value as any)?.code || 500;

    } catch (error) {
      // TODO handle error
      return 500;
    }
  };

  /**
   * Request reset password link
   */
  const initiateChat = async (location: string, locationDid: string, agentDid?: string): Promise<ApiReturn | null> => {
    try {
      const {data} = await useFetch('/public/auth/chat/initiate', {
        baseURL: config.public.apiUrl,
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify({
          location,
          locationDid,
          agentDid,
        }),
      });

      return (data.value as ApiReturn) || null;

    } catch (error) {
      return null;
    }
  };

  /**
   * Reset password
   */
  const resetPassword = async (password: string, resetToken: string, recaptchaToken: string): Promise<number> => {
    try {
      const {data} = await useFetch('/public/auth/reset-password', {
        baseURL: config.public.apiUrl,
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify({
          password,
          resetToken,
          token: recaptchaToken,
        }),
      });

      return (data.value as any)?.code || 500;

    } catch (error) {
      // TODO handle error
      return 500;
    }
  };

  /**
   * Update user data
   */
  const update = async (userData: UserUpdatePayload): Promise<number> => {
    try {
      const {data} = await useFetch('/public/user', {
        baseURL: config.public.apiUrl,
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify(userData),
      });

      if (data.value && (data.value as any).code === 200) {
        const authUserStore = useAuthUserStore();
        authUserStore.setUser((data.value as any).data);
      }

      return (data.value as any)?.code || 500;

    } catch (error) {
      // TODO handle error
      return 500;
    }
  };

  /**
   * Email enquiry
   */
  const emailEnquiry = async (enquiryData: {name: string; email: string; phone?: string; message: string; entity: 'listing' | 'builder' | 'development', entityId: string}): Promise<number> => {
    try {
      const {data} = await useFetch('/public/email-enquiry', {
        baseURL: config.public.apiUrl,
        method: 'POST',
        headers: globalApiHeaders(),
        body: JSON.stringify(enquiryData),
      });

      if (data.value && (data.value as any).code === 200) {
        const authUserStore = useAuthUserStore();
        authUserStore.setUser((data.value as any).data);
      }

      return (data.value as any)?.code || 500;

    } catch (error) {
      // TODO handle error
      return 500;
    }
  };

  return {
    provide: {
      validateAuth,
      register,
      login,
      logout,
      verifyEmail,
      requestResetPasswordLink,
      resetPassword,
      setUserId,
      update,
      emailEnquiry,
      initiateChat,
    },
  };
});

/**
 * Check every 10 seconds whether the auth still valid
 *
 * @param initialCall
 */
function runValidateAuth (initialCall = false) {
  if (!initialCall) {
    validateAuth();
  }
  setTimeout(() => {
    runValidateAuth();
  }, 10000);
}
