function initializeFacebook() {
  (window as any).fbAsyncInit = function() {
    (window as any).FB.init({
      appId      : '564076659141685',
      cookie     : true,
      xfbml      : true,
      version    : 'v17.0',
    });
    (window as any).FB.AppEvents.logPageView();
  };

  (function(d, s, id){
    // eslint-disable-next-line prefer-const
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    // eslint-disable-next-line prefer-const
    js = d.createElement(s); js.id = id;
    (js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
    (fjs as any).parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    window.addEventListener('load', initializeFacebook);
  }
});
