<template>
  <!-- Website header -->
  <header :class="{'--sticky': stickyMenuValue > 0.5, '--mobile-menu-opened': layoutStore.isMobileMenuOpen}" :style="`background: rgba(var(--primary), ${stickyMenuValue}); padding: ${stickyMenuPaddingValue}px 0`">
    <div class="__mobile-menu-backdrop" />
    <div class="container">
      <div class="row __header-inner">
        <!-- Website logo -->
        <LayoutBrandLogo :is-light="stickyMenuValue < 0.5 && !layoutStore.isMobileMenuOpen" />

        <!-- Website main navigation -->
        <LayoutHeaderNav />

        <div class="action-wrapper">
          <ElementsAvatar :icon-src="favoriteSelectedIcon" icon-alt="Favorite icon" icon-link="/auth/my-account?selectedMenuItem=favorites" />
          <ElementsAvatar :icon-src="displayUserIcon" :full-width="!!authUserStore.user" icon-alt="User icon" style="cursor:pointer;" @click="triggerUserIconClickAction" @mouseenter="lastUserMenuBlur = 0; isUserMenuHovered = true;" @mouseleave="isUserMenuHovered = false;" />
          <div v-if="authUserStore.user" class="__user-menu" :class="{'--visible': showUserMenu}" @mouseenter="lastUserMenuBlur = 0; isUserMenuHovered = true;" @mouseleave="isUserMenuHovered = false;">
            <ul>
              <li>
                <nuxt-link to="/auth/my-account">
                  My Account
                </nuxt-link>
              </li>
              <li @click="triggerUserLogout">
                Logout
              </li>
            </ul>
          </div>
        </div>

        <div class="__mobile-menu-trigger" @click="toggleMobileMenu">
          <ElementsButtonsHamburger :is-open="layoutStore.isMobileMenuOpen" :is-light="stickyMenuValue < 0.5 && !layoutStore.isMobileMenuOpen" />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import userIcon from '~/assets/images/icons/user.webp';
import favoriteSelectedIcon from '~/assets/images/icons/favorite-selected.webp';
import {useLayoutStore} from '~/store/layout';
import {ComputedRef, Ref} from 'vue';
import {useAuthUserStore} from '~/store/authUser';
import {useNuxtApp, useRouter} from '#imports';

// DATA
const layoutStore = useLayoutStore();
const authUserStore = useAuthUserStore();
const route = useRoute();
const router = useRouter();
const showUserMenu = ref(false);
const lastUserMenuBlur = ref(0);
const isUserMenuHovered = ref(false);
const {$logout} = useNuxtApp();

/**
 * When route path change
 */
watch(() => route.path, () => {
  if (layoutStore.isMobileMenuOpen) {
    layoutStore.toggleMobileMenuStatus();
  }
});

/**
 * Sticky menu opacity level
 */
const stickyMenuValue: ComputedRef<number> = computed(() => {
  if (route && route.path === '/') {
    if (layoutStore.scrollY < 100) {
      return layoutStore.scrollY/100;
    }
  }
  return 1;
});

/**
 * Count last user menu blur
 */
function countLastUserMenuBlur () {
  if (showUserMenu.value) {
    if (isUserMenuHovered.value) {
      lastUserMenuBlur.value = 0;
    } else {
      lastUserMenuBlur.value += 100;
    }
    if (lastUserMenuBlur.value > 400) {
      showUserMenu.value = false;
    }
    setTimeout(() => {
      countLastUserMenuBlur();
    }, 100);
  }
}

/**
 * Trigger user icon click action
 */
function triggerUserIconClickAction () {
  if (authUserStore.user) {
    showUserMenu.value = !showUserMenu.value;
    countLastUserMenuBlur();
  } else {
    router.push('/auth/login');
  }
}

/**
 * Trigger user logout
 */
function triggerUserLogout () {
  $logout();
  showUserMenu.value = false;
}

/**
 * Sticky menu padding value
 */
const stickyMenuPaddingValue: ComputedRef<number> = computed(() => {
  return 30 - (stickyMenuValue.value * 15);
});

/**
 * Toggle mobile menu open close status
 */
function toggleMobileMenu () {
  layoutStore.toggleMobileMenuStatus();
}

/**
 * Display user icon
 */
const displayUserIcon: Ref<string> = computed(() => {
  if (authUserStore.user) {
    return authUserStore.user.avatarUrl ? authUserStore.user.avatarUrl : '/images/avatars/blank.webp';
  }
  return userIcon;
});
</script>

<style lang="scss" scoped>
.__header-inner {
  position: relative;

  .__user-menu {
    position: absolute;
    background: #4d4c88;
    top: 40px;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    display: flex;
    transition: all 0.25s ease-in-out;

    ul {
      display: flex;
      flex-direction: column;
      width: 140px;

      li {
        display: flex;
        flex-wrap: nowrap;
        color: white;
        padding: 12px;
        width: 100%;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        transition: color 0.4s ease-in-out;
        position: relative;

        &:hover {
          background: #3a3a6d;
        }

        a {
          color: white;
          text-decoration: none;
        }
      }
    }

    &.--visible {
      padding: 12px 0 0;
      max-height: 100px;
      box-shadow: 0 10px 8px 2px rgba(0, 0, 0, 0.2);
      transition: all 0.25s ease-in-out;
    }
  }
}
</style>
