import { defineStore } from 'pinia';
import {ListingData} from '~/@types/listing';

export interface FilterBy {
  field: string;
  value: string | null;
}

export type ListingViewType = 'Map' | 'List' | 'Mobile-Map';
export interface ListingState {
  listingData: ListingData;
  searchFields: string[];
  requestId: string | null;
  viewType: ListingViewType;
  hoveredItemSlug: string | null;
  hasUserChangedMapBounds: boolean;
  isLoading: boolean;
  companySlug: string | null,
  lastFetchedParams: string | null;
}

export const useListingStore = defineStore('listing', {
  state: (): ListingState => ({
    listingData: {
      found: 0,
      facet_counts: [],
      hits: [],
      out_of: 0,
      page: 1,
      request_params: {
        per_page: 0,
      },
    },
    searchFields: ['name', 'address', 'addressOverride', 'description', 'company.name', 'features', 'listingTypes', 'searchHelper'],
    requestId: null,
    hoveredItemSlug: null,
    hasUserChangedMapBounds: false,
    viewType: 'Map',
    isLoading: false,
    companySlug: null, // Filter for company only listings
    lastFetchedParams: null,
  }),
  getters: {
    //
  },
  actions: {
    setListingData (listingData: any) {
      this.listingData = listingData;
    },

    setRequestId (requestId: string | null) {
      this.requestId = requestId;
    },
    setHoveredListingSlug (slug: string | null) {
      this.hoveredItemSlug = slug;
    },
    setHasUserChangedMapBounds (hasUserChangedMapBounds: boolean) {
      this.hasUserChangedMapBounds = hasUserChangedMapBounds;
    },
    setViewType (viewType: ListingViewType) {
      this.viewType = viewType;
    },
    setIsLoading (isLoading: boolean) {
      this.isLoading = isLoading;
    },
    setCompanySlug (companySlug: string | null) {
      this.companySlug = companySlug;
    },
    setLastFetchedParams (lastFetchedParams: string | null) {
      this.lastFetchedParams = lastFetchedParams;
    },
  },
});
