/**
 * Check whether the given element is in view
 *
 * @param el
 * @param bufferPixelValue
 */
export default function isInView(el:HTMLElement, bufferPixelValue = 0) {
  const box = el.getBoundingClientRect();
  return (box.top + bufferPixelValue) < window.innerHeight && box.bottom >= 0;
}
